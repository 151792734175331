<template>
  <div id="tags-tab">
    <div v-if="tab === 'products'" class="white pb-6">
      <v-container grid-list-xs v-if="!loading">
        <EmptyView v-if="noProducts" :message="emptyProductsText" />
        <v-row v-else justify="center" align="center">
          <v-col v-for="(product, i) in products" :key="i" cols="6" lg="3">
            <ProductCard :product="product" />
          </v-col>
        </v-row>
        <LoadMoreButton
          v-show="hasMoreProducts"
          :loading="loadingMoreProducts"
          @loadmore="loadMoreProducts"
        />
      </v-container>
    </div>
    <div v-if="tab === 'shops'" class="white pb-6">
      <v-container grid-list-xs v-if="!loading">
        <EmptyView v-if="noShops" :message="emptyShopsText" />
        <v-row v-else justify="center" align="center">
          <v-col v-for="(shop, i) in shops" :key="i" cols="6" lg="3">
            <ShopCard :shop="shop" />
          </v-col>
        </v-row>
        <LoadMoreButton
          v-show="hasMoreShops"
          :loading="loadingMoreShops"
          @loadmore="loadMoreShops"
        />
      </v-container>
    </div>
    <LoadingView v-if="loading" />
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import ShopCard from "@/components/layouts/ShopCard.vue";
import ProductCard from "@/components/layouts/ProductCard.vue";
import LoadingView from "@/components/layouts/LoadingView.vue";
import EmptyView from "@/components/layouts/EmptyView.vue";
import LoadMoreButton from "@/components/layouts/LoadMoreButton.vue";

export default {
  name: "TagItems",
  props: { tab: String },
  components: {
    ShopCard,
    ProductCard,
    LoadingView,
    EmptyView,
    LoadMoreButton,
  },

  mounted() {
    this.tag = this.$router.currentRoute.query.tag;
    this.getItems();
  },
  data() {
    return {
      tag: "",
      shops: [],
      products: [],
      loading: true,
      loadingMoreShops: false,
      loadingMoreProducts: false,
      hasMoreShops: false,
      hasMoreProducts: false,
      lastShopDoc: null,
      lastProductDoc: null,
      loadLimit: 20,
      emptyShopsText: "Hakuna maduka yenye kitambulisho hiki",
      emptyProductsText: "Hakuna bidhaa au huduma zenye kitambulisho hiki",
    };
  },
  methods: {
    async getItems() {
      this.loading = true;
      const db = firebase.firestore();
      const shopsRef = db
        .collection("Shops")
        .where("tags", "array-contains", this.tag)
        .limit(this.loadLimit);
      const productsRef = db
        .collection("Products")
        .where("tags", "array-contains", this.tag)
        .limit(this.loadLimit);

      switch (this.tab) {
        case "shops":
          this.getShops(shopsRef);
          break;
        case "products":
          this.getProducts(productsRef);
          break;
      }
    },
    async getProducts(ref) {
      const snapshot = await ref.get().catch((error) => console.log(error));
      const docs = snapshot.docs;
      this.products = docs;
      this.products.sort(() => Math.random() - 0.5);
      this.loading = false;
      this.hasMoreProducts = docs.length == this.loadLimit;
      this.lastProductDoc = docs[docs.length - 1];
    },
    async getShops(ref) {
      const snapshot = await ref.get().catch((error) => console.log(error));
      const docs = snapshot.docs;
      this.shops = docs;
      this.shops.sort(() => Math.random() - 0.5);
      this.loading = false;
      this.hasMoreShops = docs.length == this.loadLimit;
      this.lastShopDoc = docs[docs.length - 1];
    },
    async loadMoreProducts() {
      this.loadingMoreProducts = true;
      const db = firebase.firestore();
      const ref = db
        .collection("Products")
        .where("tags", "array-contains", this.tag)
        .limit(this.loadLimit);
      const snapshot = await ref
        .startAfter(this.lastProductDoc)
        .get()
        .catch((error) => {
          this.loadingMoreProducts = false;
          console.log(error);
        });

      const docs = snapshot.docs;
      this.hasMoreProducts = docs.length == this.loadLimit;
      this.lastProductDoc = docs[docs.length - 1];
      docs.forEach((doc) => {
        this.products.push(doc);
      });
      this.loadingMoreProducts = false;
    },

    async loadMoreShops() {
      this.loadingMoreShops = true;
      const db = firebase.firestore();
      const ref = db
        .collection("Shops")
        .where("tags", "array-contains", this.tag)
        .limit(this.loadLimit);
      const snapshot = await ref
        .startAfter(this.lastShopDoc)
        .get()
        .catch((error) => {
          this.loadingMoreShops = false;
          console.log(error);
        });

      const docs = snapshot.docs;
      this.hasMoreShops = docs.length == this.loadLimit;
      this.lastShopDoc = docs[docs.length - 1];
      docs.forEach((doc) => {
        this.shops.push(doc);
      });
      this.loadingMoreShops = false;
    },
  },
  computed: {
    noProducts() {
      return this.products.length == 0;
    },
    noShops() {
      return this.shops.length == 0;
    },
  },
};
</script>
