<template>
  <v-btn text color="grey " class="text-capitalize" @click="$emit('back')">{{
    backText
  }}</v-btn>
</template>

<script>
export default {
  name: "PrevioudStepButton",
  data() {
    return {
      backText: "Rudi",
    };
  },
};
</script>

