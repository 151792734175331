<template>
  <div id="location">
    <v-container>
      <v-row v-if="location" justify="center" align="center" class="my-4">
        <h1 class="text-center">{{ region }}</h1>
        <v-icon
          aria-label="Edit Location Button"
          color="primary"
          large
          class="ma-4"
          @click="dialog = true"
          >mdi-home-edit</v-icon
        >
      </v-row>
      <v-container v-if="!loading">
        <EmptyView v-if="noShops" :message="emptyShopsText" />
        <v-row v-else align="center">
          <v-col v-for="shop in shops" :key="shop.id" cols="6" lg="3">
            <ShopCard :shop="shop" />
          </v-col>
          <LoadMoreButton
            v-show="hasMore"
            :loading="loadingMore"
            @loadmore="loadMoreShops"
          />
        </v-row>
      </v-container>
      <LoadingView v-if="loading" />

      <v-dialog
        v-model="dialog"
        scrollable
        persistent
        max-width="500px"
        transition="dialog-transition"
      >
        <v-card color="white">
          <v-card-title primary-title>
            {{ selectRegionTitle }}
          </v-card-title>
          <v-card-text>
            <v-list color="white">
              <v-list-item
                v-for="(region, i) in regions"
                :key="i"
                @click="selectRegion(region.name)"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="region.name"> </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import { regions } from "@/assets/data/location_data.json";
import firebase from "firebase/compat/app";
import ShopCard from "@/components/layouts/ShopCard.vue";
import LoadingView from "@/components/layouts/LoadingView.vue";
import EmptyView from "@/components/layouts/EmptyView.vue";
import LoadMoreButton from "@/components/layouts/LoadMoreButton.vue";

export default {
  name: "Location",
  components: {
    ShopCard,
    LoadingView,
    EmptyView,
    LoadMoreButton,
  },

  data() {
    return {
      region: "",
      hasRegion: false,
      dialog: false,
      hasMore: false,
      loadLimit: 20,
      selectRegionTitle: "Chagua Mkoa",
      regions: regions,
      shops: [],
      loading: true,
      loadingMore: false,
      error: false,
      emptyShopsText: "Hakuna maduka yaliyo sajiliwa kwenye mkoa huu",
      lastDoc: null,
    };
  },
  mounted() {
    this.region = this.$router.currentRoute.query.region;
    this.getShops();
  },
  methods: {
    async getShops() {
      this.loading = true;

      this.shops = [];
      if (!this.region) this.region = localStorage.region;
      if (!this.region) this.dialog = true;
      const db = firebase.firestore();
      const ref = db
        .collection("Shops")
        .where("region", "==", this.region)
        .limit(this.loadLimit);
      const snapshot = await ref.get().catch((error) => {
        this.error = true;
        console.log(error);
      });
      const docs = snapshot.docs;
      this.lastDoc = docs[docs.length - 1];
      this.shops = docs;
      this.hasMore = docs.length === this.loadLimit;
      this.shops.sort(() => Math.random() - 0.5);
      this.loading = false;
    },
    async loadMoreShops() {
      this.loadingMore = true;
      const db = firebase.firestore();
      const ref = db
        .collection("Shops")
        .where("region", "==", this.region)
        .limit(this.loadLimit);
      const snapshot = await ref
        .startAfter(this.lastDoc)
        .get()
        .catch((error) => {
          this.loadingMore = false;
          this.error = true;
          console.log(error);
        });
      const docs = snapshot.docs;
      this.lastDoc = docs[docs.length - 1];
      this.hasMore = docs.length === this.loadLimit;

      docs.forEach((doc) => {
        this.shops.push(doc);
      });
      this.loadingMore = false;
    },
    selectRegion(region) {
      this.region = region;
      this.dialog = false;
      localStorage.region = region;
      this.getShops();
    },
  },
  computed: {
    location() {
      if (this.region) return true;
      else return false;
    },
    noShops() {
      return this.shops.length == 0;
    },
  },
};
</script>
