<template>
  <div id="comments">
    <CommentsView v-if="comments.length > 0" :comments="fewComments" />
    <v-row v-if="comments.length > 0" justify="center" class="mb-4">
      <v-btn text rounded class="text-capitalize" @click="dialog = !dialog">{{
        viewAllText
      }}</v-btn>
    </v-row>
    <v-dialog
      v-model="dialog"
      scrollable
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <CommentsView
        :comments="comments"
        :close="true"
        @close="dialog = !dialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import CommentsView from "@/components/layouts/CommentsView";
import firebase from "firebase/compat/app";

export default {
  components: { CommentsView },
  mounted() {
    this.getComments();
  },
  data() {
    return {
      commentsText: "Comments",
      viewAllText: "View All",
      dialog: false,
      comments: [],
    };
  },
  computed: {
    fewComments() {
      let comments = this.comments;
      if (comments.length > 5) {
        comments.splice(0, comments.length - 5);
        return comments;
      } else return comments;
    },
  },

  methods: {
    async getComments() {
      try {
        const db = firebase.firestore();
        const collection = this.getCollection();
        const id = this.$router.currentRoute.query.id;
        const ref = db.collection(`${collection}/${id}/Comments`);
        const snapshot = await ref.get();
        const docs = snapshot.docs;
        this.comments = docs;
      } catch (error) {
        console.log(error);
      }
    },
    getCollection() {
      const pageName = this.$router.currentRoute.name;
      switch (pageName) {
        case "Product":
          return "Products";
        case "Shop":
          return "Shops";
        default:
          return "";
      }
    },
  },
};
</script>
