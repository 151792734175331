<template>
  <v-container class="white">
    <LoadingView v-if="loading" />
    <ErrorView v-if="error" />
    <NotFound v-if="badUrl" />
    <v-row v-if="!badUrl && !error && !loading" justify="center">
      <v-col>
        <ShopProducts />
        <v-col v-if="$vuetify.breakpoint.mobile">
          <v-divider class="my-6 mx-auto"></v-divider>
        </v-col>
        <ShopInfo :shop="shop" @updateshop="getShop" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from "firebase/compat/app";
import ShopProducts from "@/components/shop/ShopProducts.vue";
import ShopInfo from "@/components/shop/ShopInfo.vue";
import LoadingView from "@/components/layouts/LoadingView.vue";
import ErrorView from "@/components/layouts/ErrorView.vue";
import NotFound from "@/views/NotFound.vue";

export default {
  name: "Shop",

  components: {
    ShopProducts,
    ShopInfo,
    LoadingView,
    ErrorView,
    NotFound,
  },

  mounted() {
    this.getShop();
  },

  watch: {
    $route(to, from) {
      const currentShopId = from.query.id;
      const toShopId = to.query.id;
      if (currentShopId !== toShopId) this.getShop();
    },
  },

  data() {
    return {
      shopId: "",
      shop: {},
      loading: false,
      error: false,
      badUrl: false,
    };
  },
  metaInfo() {
    if (this.loading) return this.defaultMeta();
    else return this.getMeta();
  },
  methods: {
    async getShop() {
      this.loading = true;
      this.shopId = this.$router.currentRoute.query.id;
      if (!this.shopId) return;
      var db = firebase.firestore();
      var docRef = db.collection("Shops").doc(this.shopId);
      const doc = await docRef.get().catch((error) => {
        console.log(error);
        this.error = true;
        this.loading = false;
      });
      if (this.error) return;
      if (!doc.exists) {
        this.loading = false;
        this.badUrl = true;
        return;
      }
      this.shop = doc.data();
      this.loading = false;
    },

    getMeta() {
      return {
        title: this.shop.name,
        titleTemplate: "%s - MadukaYetu",
        htmlAttrs: {
          lang: "en-US",
        },
        meta: [
          { charset: "utf-8" },
          {
            name: "og:title",
            content: this.shop.name + " | MadukaYetu",
          },
          {
            name: "description",
            vmid: "description",
            content: this.shop.description,
          },
          {
            name: "og:description",
            content: this.shop.description,
          },
          {
            name: "og:url",
            content:
              window.location.origin + this.$router.currentRoute.fullPath,
          },
          {
            name: "og:image",
            content: this.shop["image_url"],
          },
          { name: "viewport", content: "width=device-width, initial-scale=1" },
        ],
      };
    },
    defaultMeta() {
      return {
        title: "Duka na Biashara",
        titleTemplate: "%s - MadukaYetu",
        htmlAttrs: {
          lang: "en-US",
        },
        meta: [
          { charset: "utf-8" },

          {
            name: "description",
            vmid: "description",
            content:
              "Wasiliana moja kwa moja na wauzaji na watoa huduma kwenye MadukaYetu",
          },
          {
            name: "og:description",
            content:
              "Wasiliana moja kwa moja na wauzaji na watoa huduma kwenye MadukaYetu",
          },
          {
            name: "og:url",
            content:
              window.location.origin + this.$router.currentRoute.fullPath,
          },
          {
            name: "og:image",
            content: "https://madukayetu.co.tz/app/icons/Icon-192.png",
          },
        ],
      };
    },
  },
};
</script>

