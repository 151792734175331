<template>
  <v-row class="px-1 mb-8" justify="center">
    <v-chip
      v-for="tag in tags"
      :key="tag"
      color="primary"
      class="ma-1"
      @click="goToTag(tag)"
      >{{ tag }}</v-chip
    >
  </v-row>
</template>

<script>
export default {
  name: "SearchRecommendations",
  data() {
    return {
      tags: ["Health", "Chakula", "Fashion", "Nguo", "Simu"],
    };
  },
  methods: {
    goToTag(tag) {
      this.$router.push({ name: "Tag", query: { tag: tag } });
      window.scrollTo(0, 0);
      this.$gtag.event("SearchRecommendation Tag", { tag: tag });
    },
  },
};
</script>

