<template>
  <v-card-text v-if="show">
    <v-row justify="center">
      <v-col v-for="(detail, i) in details" :key="i">
        <v-icon
          :aria-label="`${detail.key} Button`"
          :color="detail.available ? 'primary' : 'grey'"
          @click="openDialog(detail)"
          >{{ `mdi-${detail.icon}` }}</v-icon
        >
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="500px" transition="dialog-transition">
      <v-card color="white">
        <v-card-title> {{ selected.title }} </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="currentDetail"
            :loading="loading"
            :label="selected.title"
          >
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text class="text-capitalize" @click="update">{{
            closeText
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      timeout="2000"
      rounded="pill"
      :color="error ? 'error' : 'success'"
    >
      {{ snackbarText }}
    </v-snackbar>
  </v-card-text>
</template>

<script>
import firebase from "firebase/compat/app";

export default {
  name: "ShopContactDetails",
  props: {
    shop: Object,
  },
  computed: {
    show() {
      const currentUser = this.$store.state.currentUser;
      const uid = currentUser.id;
      const admin = currentUser.is_admin;
      const creator = this.shop.created_by === uid;
      const owner = this.shop.owner === uid;

      return admin || creator || owner;
    },
  },
  data() {
    return {
      dialog: false,
      snackbar: false,
      error: false,
      loading: false,
      snackbarText: "Taarifa zimehifadhiwa",
      closeText: "Mailza",
      selected: {},
      currentDetail: "",
      details: [
        {
          title: "Simu",
          key: "phone",
          icon: "phone",
          available: this.shop.phone ? true : false,
        },
        {
          title: "WhatsApp",
          key: "whatsapp",
          icon: "whatsapp",
          available: this.shop.whatsapp ? true : false,
        },
        {
          title: "Instagram",
          key: "instagram",
          icon: "instagram",
          available: this.shop.instagram ? true : false,
        },
        {
          title: "Facebook",
          key: "facebook",
          icon: "facebook",
          available: this.shop.facebook ? true : false,
        },
        {
          title: "Twitter",
          key: "twitter",
          icon: "twitter",
          available: this.shop.twitter ? true : false,
        },
        {
          title: "Website",
          key: "website",
          icon: "web",
          available: this.shop.website ? true : false,
        },
      ],
    };
  },
  methods: {
    openDialog(detail) {
      this.selected = detail;
      this.currentDetail = this.shop[detail.key];
      this.dialog = true;
    },
    async update() {
      if (this.currentDetail === this.shop[this.selected.key])
        this.dialog = false;
      else {
        this.loading = true;
        const db = firebase.firestore();
        const shopId = this.$router.currentRoute.query.id;
        const ref = db.collection("Shops").doc(shopId);
        const field = this.selected.key;
        const data = {};
        data[field] = this.currentDetail;
        await ref.update(data).catch((error) => {
          this.loading = false;
          this.snackbarText = "Kuna hitilafu imetokea, tafadhali jaribu tena";
          this.error = true;
          this.snackbar = true;
          console.log(error);
        });
        if (this.error) return;
        this.snackbarText = "Taarifa Zimehifadhiwa";
        this.loading = false;
        this.dialog = false;
        this.snackbar = true;
        this.$emit("updateshop");
      }
    },
  },
};
</script>

