<template>
  <div grid-list-xs class="white">
    <v-row justify="center" align="center" class="my-2 mx-0">
      <h1 class="text-center">{{ tag }}</h1>
      <ShareButton type="Kitambulisho" />
    </v-row>
    <v-tabs v-model="tab" background-color="white" color="primary" centered>
      <v-tab v-for="tab in tabs" :key="tab.key" class="text-capitalize">
        {{ tab.title }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="tab in tabs" :key="tab.key">
        <TagItems :tab="tab.key" />
      </v-tab-item>
    </v-tabs-items>
    <v-fab-transition>
      <v-btn
        fab
        color="primary"
        bottom
        right
        fixed
        class="mr-lg-11 mb-lg-11"
        @click="updateTags"
      >
        <v-icon aria-label="Add / Remove Tag Button">{{
          `mdi-${icon}`
        }}</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-snackbar
      :value="snackbar"
      timeout="2000"
      rounded="pill"
      color="primary"
      elevation="8"
      >{{ tagAddedText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          rounded
          v-bind="attrs"
          @click="goToRecommendations"
          class="text-capitalize"
        >
          {{ openText }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import TagItems from "@/components/tag/TagItems.vue";
import ShareButton from "@/components/layouts/ShareButton.vue";
export default {
  name: "Tag",
  components: { TagItems, ShareButton },

  data() {
    return {
      tag: "",
      tabs: [
        { title: "Huduma na Bidhaa", key: "products" },
        { title: "Maduka", key: "shops" },
      ],
      tab: null,
      tags: [],
      snackbar: false,
      openText: "Fungua",
      show: false,
    };
  },
  mounted() {
    this.tag = this.$router.currentRoute.query.tag;
    this.getTags();
    this.show = true;
  },
  metaInfo() {
    return this.getMeta();
  },
  computed: {
    tagAddedText() {
      return `'${this.tag}' imeongezwa`;
    },
    icon() {
      if (this.tags && this.tags.includes(this.tag)) return "heart";
      else return "heart-outline";
    },
  },
  methods: {
    updateTags() {
      if (this.tags.includes(this.tag)) this.removeTag();
      else this.addTag();
    },
    getTags() {
      this.tags = JSON.parse(localStorage.getItem("tags"));
    },
    removeTag() {
      const index = this.tags.indexOf(this.tag);
      this.tags.splice(index, 1);
      const parsed = JSON.stringify(this.tags);
      localStorage.setItem("tags", parsed);
      this.snackbar = true;
    },
    addTag() {
      this.show = false;
      this.tags.push(this.tag);
      const parsed = JSON.stringify(this.tags);
      localStorage.setItem("tags", parsed);
      this.snackbar = true;
    },
    goToRecommendations() {
      this.snackbar = false;
      this.$router.push({ name: "Recommendations" });
    },
    getMeta() {
      return {
        title: this.tag,
        titleTemplate: "%s - MadukaYetu",
        htmlAttrs: {
          lang: "en-US",
        },
        meta: [
          { charset: "utf-8" },
          {
            name: "og:title",
            content: this.tag + " | MadukaYetu",
          },
          {
            name: "description",
            vmid: "description",
            content:
              "Pitia bidhaa zenye kitambulisho " +
              this.tag +
              " kwenye MadukaYetu",
          },
          {
            name: "og:description",
            content:
              "Pitia bidhaa zenye kitambulisho " +
              this.tag +
              " kwenye MadukaYetu",
          },
          {
            name: "og:url",
            content:
              window.location.origin + this.$router.currentRoute.fullPath,
          },
          {
            name: "og:image",
            content: "https://madukayetu.co.tz/app/icons/Icon-192.png",
          },
          { name: "viewport", content: "width=device-width, initial-scale=1" },
        ],
      };
    },
  },
};
</script>

