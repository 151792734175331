<template>
  <div id="recommendations-sheet">
    <v-dialog
      :value="showRecommendations"
      color="primary"
      :overlay="false"
      :max-width="$vuetify.breakpoint.mobile ? '100%' : '50vw'"
    >
      <v-card color="primary" dark class="mx-auto py-4">
        <v-img
          :src="image"
          contain
          :max-width="$vuetify.breakpoint.mobile ? '100%' : '20vw'"
          max-height="30vh"
          class="mx-auto"
        ></v-img>

        <v-card-title class="text-center">
          <h2 class="text-center mx-auto">
            {{ title }}
          </h2>
        </v-card-title>
        <v-card-text class="text-center">
          <p class="mx-auto">
            {{ subtitle }}
          </p>
        </v-card-text>
        <v-card-text>
          <v-row justify="center" class="col-lg-8 mx-auto">
            <v-progress-circular
              v-if="loading"
              indeterminate
              color="white"
            ></v-progress-circular>
            <v-chip
              v-for="(tag, i) in tags"
              :key="i"
              class="ma-1"
              color="white"
              :outlined="selected(tag)"
              light
              filter
              filter-icon="mdi-plus"
              :input-value="selected(tag)"
              @click="updateSelected(tag)"
            >
              {{ tag }}
            </v-chip>
          </v-row>
        </v-card-text>
        <v-card-actions style="justify-content: center">
          <v-row class="col-lg-4 my-4 mx-2">
            <v-btn
              rounded
              light
              block
              large
              :disabled="noSelectedTags"
              color="white"
              elevation="8"
              @click="finish"
              class="text-capitalize"
              >{{ doneText }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";

export default {
  name: "RecommendationsSheet",

  data() {
    return {
      image: require("@/assets/images/img_recommend.svg"),
      title: "Mapendekezo",
      subtitle: "Karibu kuchagua vitu mbalimbali unavyo pendelea kufuatilia",
      tags: [],
      loading: false,
      selectedTags: [],
      doneText: "Maliza",
      showRecommendations: false,
    };
  },
  methods: {
    hasTags() {
      const isFirstOpen = localStorage.getItem("first-open") ? false : true;
      const hasTags = localStorage.getItem("tags") ? true : false;
      if (!isFirstOpen && !hasTags) this.showRecommendations = true;
      else this.showRecommendations = false;
    },
    async getRecommendations() {
      this.loading = true;
      const db = firebase.firestore();
      const ref = db.collection("InitialPreferenceTags");
      const snapshot = await ref.get().catch((error) => {
        this.loading = false;
        console.log(error);
      });
      snapshot.docs.forEach((doc) => {
        const tag = doc.data().title;
        this.tags.push(tag);
      });

      this.loading = false;
    },
    updateSelected(tag) {
      const indexOfTag = this.selectedTags.indexOf(tag);
      if (this.selectedTags.includes(tag))
        this.selectedTags.splice(indexOfTag, 1);
      else this.selectedTags.push(tag);
    },
    selected(tag) {
      return !this.selectedTags.includes(tag);
    },
    finish() {
      const parsed = JSON.stringify(this.selectedTags);
      localStorage.setItem("tags", parsed);
      this.showRecommendations = false;
      this.$emit("done");
    },
  },
  mounted() {
    this.hasTags();
    if (this.showRecommendations) this.getRecommendations();
  },
  computed: {
    noSelectedTags() {
      return this.selectedTags.length == 0;
    },
  },
};
</script>


