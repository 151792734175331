<template>
  <div justify="center" align="center" class="py-4 mb-8 mt-4">
    <v-row justify="center" align="center" class="col-lg-8">
      <v-col>
        <v-btn @click="goTo('Shop')" icon class="text-capitalize py-4">
          <v-avatar color="primary">
            <v-icon color="white">mdi-store</v-icon>
          </v-avatar>
        </v-btn>
        <p class="my-2">
          {{ createShopText }}
        </p>
      </v-col>
      <v-col v-for="shortcut in shortcuts" :key="shortcut.title">
        <v-btn @click="goTo(shortcut.destination)" icon>
          <v-avatar color="primary">
            <v-icon color="white">{{ `mdi-${shortcut.icon}` }}</v-icon>
          </v-avatar>
        </v-btn>
        <p class="my-2">
          {{ shortcut.title }}
        </p>
      </v-col>
    </v-row>

    <v-bottom-sheet v-model="sheet" inset scrollable>
      <v-list color="white" class="rounded-lg">
        <v-list-item @click="goToCreateShop()">
          <v-list-item-avatar color="primary">
            <v-icon color="white">mdi-store</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ createShopText }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <MyShopsList />
      </v-list>
    </v-bottom-sheet>
  </div>
</template>

<script>
import MyShopsList from "@/components/layouts/MyShopsList.vue";

export default {
  name: "Shortcuts",
  components: { MyShopsList },
  data() {
    return {
      sheet: false,
      createShopText: "Tengeneza Duka",
      shortcuts: [
        {
          title: "Bidhaa Mpya",
          destination: "New",
          icon: "star",
        },
        {
          title: "Mapendekezo",
          destination: "Recommendations",
          icon: "thumb-up",
        },
        {
          title: "Mkoani",
          destination: "Location",
          icon: "map-marker",
        },
      ],
    };
  },
  methods: {
    goTo(destination) {
      if (destination === "Shop") this.sheet = true;
      else this.$router.push({ name: destination });
    },

    goToCreateShop() {
      this.$router.push({ name: "CreateShop" });
      this.$gtag.event("Create Shop");
    },
  },
};
</script>