<template>
  <v-card-actions class="mx-auto my-6">
    <v-btn
      v-if="show"
      rounded
      outlined
      block
      color="primary"
      class="text-capitalize mb-2"
      @click="dialog = !dialog"
      >{{ udpateOwnerText }}</v-btn
    >
    <v-dialog v-model="dialog" max-width="500px" transition="dialog-transition">
      <v-card color="white">
        <v-card-title> {{ udpateOwnerText }} </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="query"
            :loading="loading"
            :label="ownerEmailText"
            v-on:keyup.enter="search"
            :hint="error"
            :error="error"
          >
            <v-icon
              aria-label="Search User"
              slot="append"
              color="primary"
              @click="search"
            >
              mdi-account-search
            </v-icon>
          </v-text-field>
        </v-card-text>
        <v-card-text>
          <v-list color="white">
            <v-list-item @click="update">
              <v-list-item-avatar>
                <v-img :src="result.image_url"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ result.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ result.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text class="text-capitalize" @click="dialog = !dialog">{{
            closeText
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="2000" rounded="pill">
      {{ snackbarText }}
    </v-snackbar>
  </v-card-actions>
</template>

<script>
import firebase from "firebase/compat/app";

export default {
  name: "UpdateOwnerButton",
  props: {
    shop: Object,
  },
  mounted() {
    this.showButton();
    this.getOwner();
  },
  data() {
    return {
      show: false,
      dialog: false,
      snackbar: false,
      loading: false,
      error: "",
      query: "",
      result: {},
      user: this.$store.state.currentUser,
      udpateOwnerText: "Milikisha Duka",
      closeText: "Maliza",
      ownerEmailText: "Email ya Mmiliki",
      snackbarText: "Taarifa zimehifadhiwa",
    };
  },
  methods: {
    showButton() {
      const loggedIn = this.$store.state.loggedIn;
      const admin = this.user.is_admin;
      const owner = this.shop.owner === this.user.id;
      const creator = this.shop.created_by === this.user.id;
      if (loggedIn && (admin || owner || creator)) this.show = true;
    },
    async getOwner() {
      if (!this.shop.owner) {
        return;
      }
      this.loading = true;
      const db = firebase.firestore();
      const ref = db.collection("Users").doc(this.shop.owner);
      const doc = await ref.get().catch((error) => {
        this.loading = false;
        console.log(error);
      });
      this.result = doc.data();
      this.result.id = doc.id;
      this.query = this.result.email;
      this.loading = false;
    },
    async search() {
      if (!this.query) return;
      this.loading = true;
      const db = firebase.firestore();
      const ref = db.collection("Users").where("email", "==", this.query);
      const snapshot = await ref.get().catch((error) => {
        this.loading = false;
        console.log(error);
      });

      const docs = snapshot.docs;
      const hasUsers = docs.length > 0;
      if (hasUsers) {
        const doc = snapshot.docs[0];
        this.result = doc.data();
        this.result.id = doc.id;
        this.error = "";
      } else {
        this.error = "Hakuna watumiaji wenye hii email";
      }
      this.loading = false;
    },

    async update() {
      this.loading = true;
      const db = firebase.firestore();
      const shopId = this.$router.currentRoute.query.id;
      const ref = db.collection("Shops").doc(shopId);
      await ref.update({ owner: this.result.id }).catch((error) => {
        this.loading = false;
        this.snackbarText = "Kuna hitilafu imetokea, tafadhali jaribu tena.";
        this.snackbar = true;
        this.dialog = false;
        console.log(error);
      });

      this.snackbarText = "Taarifa zimehifadhiwa";
      this.dialog = false;
      this.snackbar = true;
      this.loading = false;
    },
  },
};
</script>

