import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
// import firebase from 'firebase/app';
// import 'firebase/firestore';
// import 'firebase/auth';
import firebase from 'firebase/compat/app';
// import { initializeApp } from "firebase/app"
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import VueGtag from "vue-gtag";
import imageCompression from 'browser-image-compression';



Vue.config.productionTip = false


Vue.use(VueGtag, {
    config: { id: "G-99G9DS71WH" }
});

const firebaseConfig = {
    apiKey: "AIzaSyAjS4rApUqNtsVr3xbFjun_w-Vkagz4iKg",
    authDomain: "madukayetu-1f281.firebaseapp.com",
    projectId: "madukayetu-1f281",
    storageBucket: "madukayetu-1f281.appspot.com",
    messagingSenderId: "323466007838",
    appId: "1:323466007838:web:7736620f762cfadcc2a50f",
    measurementId: "G-W4ML1RJ5Y1"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// const firebaseApp =
// initializeApp(firebaseConfig);
// const auth = getAuth(firebaseApp)

const prod = process.env.NODE_ENV === 'production'
const shouldSW = 'serviceWorker' in navigator && prod
const shouldSWDev = 'serviceWorker' in navigator && !prod
if (shouldSW) {
    navigator.serviceWorker.register('/service-worker.js').then(() => {
        // console.log("Service Worker Registered!")
    })
} else if (shouldSWDev) {
    navigator.serviceWorker.register('/service-worker-dev.js').then(() => {
        // console.log('Service Worker Registered!')
    })
}



new Vue({
    router,
    store,
    vuetify,
    firebase,
    imageCompression,
    render: h => h(App)
}).$mount('#app')