<template>
  <div id="shop-info">
    <v-col lg="8" class="mx-auto">
      <h2 class="text-center">{{ shopText }}</h2>
      <v-card flat color="white" class="rounded-xl">
        <v-img
          :src="this.shop['image_url']"
          width="100%"
          class="rounded-lg"
          transition="scale-transition"
        >
          <template v-slot:placeholder>
            <Placeholder />
          </template>
          <LikeButton />
        </v-img>
        <v-card-title primary-title>
          <h1 style="word-break: normal; line-height: 1em">
            {{ shop["name"] }}
          </h1>
        </v-card-title>
        <v-card-text>
          <p>
            {{ shop["description"] }}
          </p>
        </v-card-text>
        <v-card-subtitle name="location">
          <v-btn
            text
            rounded
            class="text-capitalize"
            :to="{ path: '/location', query: { region: `${shop.region}` } }"
          >
            {{ shop["region"] }}, {{ shop["district"] }}
          </v-btn>
        </v-card-subtitle>

        <ContactButton :shop="shop" />
        <v-card-actions>
          <CreateProductButton />
        </v-card-actions>
        <UpdateOwnerButton :shop="shop" />
        <ShopContactDetails :shop="shop" @updateshop="$emit('updateshop')" />

        <v-card-actions class="mb-8 my-8">
          <v-row justify="center" align="center">
            <ShareButton />
            <CommentButton />
            <SaveShopButton />
          </v-row>
        </v-card-actions>

        <Comments />

        <v-card-text>
          <v-row>
            <v-chip
              v-for="(tag, i) in shopTags"
              :key="i"
              :to="{ name: 'Tag', query: { tag: tag } }"
              small
              class="ma-1"
              color="primary"
              >{{ tag }}</v-chip
            >
          </v-row>
        </v-card-text>

        <RelatedShops :shop="shop" />
      </v-card>
    </v-col>
  </div>
</template>

<script>
import Placeholder from "@/components/layouts/Placeholder.vue";
import ShareButton from "@/components/layouts/ShareButton.vue";
import RelatedShops from "@/components/shop/RelatedShops.vue";
import CreateProductButton from "@/components/shop/CreateProductButton.vue";
import ShopContactDetails from "@/components/shop/ShopContactDetails.vue";
import ContactButton from "@/components/layouts/ContactButton.vue";
import UpdateOwnerButton from "@/components/shop/UpdateOwnerButton.vue";
import SaveShopButton from "@/components/layouts/SaveShopButton.vue";
import CommentButton from "../layouts/CommentButton.vue";
import Comments from "@/components/layouts/Comments";
import LikeButton from "@/components/layouts/LikeButton";
export default {
  name: "ShopInfo",
  components: {
    Placeholder,
    ShareButton,
    RelatedShops,
    CreateProductButton,
    ShopContactDetails,
    ContactButton,
    UpdateOwnerButton,
    SaveShopButton,
    CommentButton,
    Comments,
    LikeButton,
  },
  props: {
    shop: Object,
  },
  data() {
    return {
      contact: "Mawasiliano",
      shopText: "Duka",
    };
  },

  computed: {
    shopTags() {
      let tags = [];
      this.shop.tags.forEach((tag) => {
        if (tag) tags.push(tag);
      });
      return tags;
    },
  },
};
</script>
