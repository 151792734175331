import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        loggedIn: false,
        currentUser: {},
        userShops: [],

    },
    getters: {
        isLoggedIn: state => state.loggedIn,
        currentUser: state => state.currentUser,
        userShops: state => state.userShops,

    },
    mutations: {
        updateLoginStatus: ((state, payload) => {
            state.loggedIn = payload
        }),
        updateCurrentUser: ((state, user) => {
            state.currentUser = user
        }),
        updateUserShops: ((state, userShops) => {
            state.userShops = userShops

        }),

    },
    actions: {},
    modules: {},

})

export default store