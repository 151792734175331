<template>
  <div id="my-shops-list">
      <v-list v-for="shop in userShops" :key="shop.id" color="white">
          <v-list-item @click="goToShop(shop)">
            <v-list-item-avatar color="primary">
              <v-img
                :src="shop.data().image_url"
                :alt="`${shop.data().name} avatar`"
              ></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ shop.data().name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name:'MyShopsList',

    computed: {
    ...mapGetters({
      userShops: "userShops",
    }),
  },
  methods: {
    goToShop(shop) {
      this.$router.push({ name: "Shop", query: { id: shop.id } });
      this.$gtag.event("My Shop");
      this.$emit('finish')
    },
  },

}
</script>

