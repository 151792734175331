<template>
  <div id="related-products">
    <v-col>
      <h3 class="mb-4">{{ realatedProductsText }}</h3>
      <v-row justify="center" align="center">
        <v-col v-for="(product, i) in relatedProducts" :key="i" cols="6" lg="4">
          <ProductCard :product="product" />
        </v-col>
      </v-row>
      <LoadMoreButton v-if="hasMore" :loading="loading" @loadmore="loadMore" />
    </v-col>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import ProductCard from "@/components/layouts/ProductCard.vue";
import LoadMoreButton from "@/components/layouts/LoadMoreButton.vue";

export default {
  name: "RelatedProducts",
  components: { ProductCard, LoadMoreButton },
  props: {
    product: Object,
  },
  mounted() {
    this.getProducts();
  },

  data() {
    return {
      realatedProductsText: "Bidhaa/Huduma Zinazoendana",
      relatedProducts: [],
      loadLimit: 12,
      hasMore: false,
      loading: false,
      error: false,
    };
  },
  methods: {
    async getProducts() {
      if (!this.product) return;
      const tags = this.product.tags;
      if (!tags) return;
      const filteredTags = this.filterTags(tags);

      const db = firebase.firestore();
      const ref = db
        .collection("Products")
        .where("tags", "array-contains-any", filteredTags)
        .limit(this.loadLimit);

      const snapshot = await ref.get().catch((error) => {
        console.log(error);
        this.loading = false;
        this.error = true;
      });
      const docs = snapshot.docs;
      this.hasMore = docs.length === this.loadLimit;
      this.lastDoc = docs[docs.length - 1];
      docs.sort(() => Math.random() - 0.5);
      this.relatedProducts = docs;
      this.loading = false;
    },

    async loadMore() {
      this.loading = true;
      const db = firebase.firestore();
      const filteredTags = this.filterTags(this.product.tags);

      const ref = db
        .collection("Products")
        .where("tags", "array-contains-any", filteredTags)
        .limit(this.loadLimit)
        .startAfter(this.lastDoc);

      const snapshot = await ref.get().catch((error) => {
        this.error = true;
        this.loading = false;
        console.log(error);
      });

      const docs = snapshot.docs;
      this.hasMore = docs.length === this.loadLimit;
      this.lastDoc = docs[docs.length - 1];
      docs.sort(() => Math.random() - 0.5);
      docs.forEach((doc) => {
        this.relatedProducts.push(doc);
      });

      this.loading = false;
    },

    filterTags(tags) {
      if (tags.length > 10) {
        tags.sort(() => Math.random() - 0.5);
        tags.splice(10, tags.length - 10);
        return tags;
      } else return tags;
    },
  },
};
</script>

