<template>
  <v-app class="white">
    <Header />

    <v-main class="white">
      <router-view />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Header from "@/components/layouts/Header.vue";
import Footer from "@/components/layouts/Footer.vue";
export default {
  name: "App",
  metaInfo() {
    return {
      title: "Maduka Yetu",
      titleTemplate: "%s - Biashara Mtandaoni",
      htmlAttrs: {
        lang: "en-US",
      },
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content:
            "Maduka Yetu inarahisisha upatikanaji wa Biashara Mtandaoni!",
        },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
      ],
    };
  },
  components: {
    Header,
    Footer,
  },
};
</script>

<style >
@import url("https://fonts.googleapis.com/css?family=Raleway:100,200,300,400,500,600,700,,800,900&display=swap");

* {
  font-family: Raleway;
}
html {
  font-family: Raleway;
  background: #fffff0;
}

p {
  font-family: Raleway;
  font-size: 20px;
  line-height: 28px;
}
.v-card,
.v-application {
  font-family: Raleway;
}
</style>
