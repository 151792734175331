<template>
  <v-card color="white" elevation="0" width="100%">
    <v-card-title>
      {{ commentsText }}
      <v-spacer></v-spacer>
      <v-btn v-if="close" icon class="ma-4" @click="onClose">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-list color="white" three-line>
        <v-list-item v-for="(comment, i) in comments" :key="i">
          <v-list-item-avatar color="primary">
            <v-img
              :src="comment.data().author_image_url"
              alt="Author Image"
            ></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="caption font-weight-bold">{{
              comment.data().author
            }}</v-list-item-title>
            <p style="font-size: 1em">{{ comment.data().text }}</p>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    comments: Array,
    close: Boolean,
  },
  data() {
    return {
      commentsText: "Comments",
    };
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
  },
};
</script>
