<template>
  <v-row justify="center" align="center" class="my-4 col-12">
    <v-btn
      rounded
      large
      outlined
      :loading="loading"
      :disabled="loading"
      color="primary"
      class="text-capitalize"
      @click="getData"
      >{{ loadMoreText }}</v-btn
    >
  </v-row>
</template>

<script>
export default {
  name: "LoadMoreButton",
  props: { loading: Boolean },
  data() {
    return {
      loadMoreText: "Pakua Zaidi",
    };
  },
  methods: {
      getData(){
          this.$emit('loadmore')
      }
  },
};
</script>
