<template>
  <div id="create-product">
    <v-container>
      <LoadingView v-if="loading" />
      <v-row v-if="!loading" justify="center" align="center">
        <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 8">
          <h1 class="text-center">{{ createProductText }}</h1>
          <v-stepper v-model="currentStep" vertical rounded="lg" class="white">
            <v-stepper-step :complete="currentStep > 1" step="1">
              {{ imageText }}
            </v-stepper-step>
            <v-stepper-content step="1">
              <v-img v-if="image" :src="selectedImage"></v-img>
              <v-file-input
                v-model="image"
                chips
                accept="image/*"
                :label="imageText"
                prepend-icon="mdi-image-plus"
                required
              ></v-file-input>

              <ContinueButton @next="next(currentStep)" />
            </v-stepper-content>

            <v-stepper-step :complete="currentStep > 2" step="2">
              {{ nameText }}
            </v-stepper-step>

            <v-stepper-content step="2">
              <v-text-field
                v-model="name"
                :hint="nameHint"
                :label="nameText"
                required
                counter
                maxlength="50"
              ></v-text-field>

              <v-textarea
                v-model="description"
                :hint="descriptionHint"
                :label="descriptionText"
                required
                counter
                maxlength="200"
              ></v-textarea>
              <v-text-field
                v-model="price"
                :hint="priceHint"
                :label="priceText"
                maxlength="20"
              ></v-text-field>
              <ContinueButton @next="next(currentStep)" />
              <PreviousStepButton @back="currentStep--" />
            </v-stepper-content>

            <v-stepper-step step="3"> {{ tagsTitle }} </v-stepper-step>
            <v-stepper-content step="3">
              <p>{{ selectTagsText }}</p>
              <v-row class="ma-1">
                <v-chip
                  v-for="(result, i) in searchResults"
                  :key="i"
                  class="ma-1"
                  color="primary"
                  filter
                  filter-icon="mdi-plus"
                  :outlined="selected(result)"
                  :input-value="selected(result)"
                  @click="updateSelected(result)"
                  >{{ result }}</v-chip
                >
              </v-row>
              <v-btn
                outlined
                rounded
                color="black"
                class="text-capitalize ma-1"
                @click="dialog = !dialog"
                >{{ searchTagText }}</v-btn
              >

              <v-col>
                <v-divider class="my-2"></v-divider>
                <v-row class="my-1 px-1">
                  <v-chip
                    v-for="(tag, i) in tags"
                    :key="i"
                    color="primary"
                    class="ma-1"
                    >{{ tag.toLowerCase() }}</v-chip
                  >
                </v-row>
              </v-col>
              <PreviousStepButton @back="currentStep--" class="my-2" />

              <v-btn
                block
                rounded
                large
                elevation="8"
                :loading="loading"
                :disabled="!allowSubmit()"
                color="primary"
                class="mr-4 my-4 text-capitalize"
                @click="submit"
              >
                {{ finishText }}
              </v-btn>
            </v-stepper-content>
          </v-stepper>
          <v-dialog
            v-model="dialog"
            max-width="500px"
            transition="dialog-transition"
            class="my-2"
          >
            <v-card color="white " rounded="lg">
              <v-card-title primary-title> {{ searchTagsText }} </v-card-title>
              <v-card-text>
                <v-text-field
                  v-model="query"
                  :label="searchTagsText"
                  :hint="hitEnterText"
                  :placeholder="tagsPlaceholder"
                  v-on:keyup.enter="searchTags"
                  :loading="loadingTags"
                ></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-row justify="end">
                  <v-btn
                    text
                    class="text-capitalize mr-4 mb-4"
                    @click="searchTags"
                    >{{ searchText }}</v-btn
                  >
                </v-row>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-snackbar
        :value="snackbar"
        rounded="pill"
        timeout="2000"
        elevation="8"
        class="text-center white--text mx-auto mt-4"
        >{{ snackbarText }}</v-snackbar
      >
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import firebase from "firebase/compat/app";
import axios from "axios";
import imageCompression from "browser-image-compression";
import LoadingView from "@/components/layouts/LoadingView.vue";
import ContinueButton from "@/components/layouts/ContinueButton.vue";
import PreviousStepButton from "@/components/layouts/PreviousStepButton.vue";

export default {
  name: "CreateProduct",
  components: { LoadingView, ContinueButton, PreviousStepButton },
  mounted() {
    this.shopId = this.$router.currentRoute.query.shop;
    this.initializeData();
  },
  data() {
    return {
      currentStep: 1,
      allow: false,
      shopId: "",
      shop: {},
      image: null,
      name: "",
      description: "",
      price: "",
      loading: false,
      loadingTags: false,
      dialog: false,
      error: false,
      snackbar: false,
      query: "",
      searchResults: [],
      tags: [],

      imageText: "Picha Ya Bidhaa au Huduma",
      createProductText: "Tengeneza Huduma au Bidhaa",
      nameText: "Jina La Bidhaa au Huduma",
      nameHint: "Mashati ya Kiume",
      descriptionText: "Utambulisho Wa Bidhaa au Huduma",
      descriptionHint: "Bidhaa bora.... Tunatuma mikoani...",
      tagsTitle: "Vitambulisho",
      selectTagsText: "Chagua maneno ya kutambulisha bidhaa au huduma",
      priceText: "Bei Ya Bidhaa au Huduma",
      priceHint: "1000",
      tagsPlaceholder:
        "Nguo, Fashion, Chakula, Afya, Saa, Vinywaji, Electronics",
      hitEnterText: "Bonyeza 'Enter' kutafuta vutambulisho",
      doneText: "Maliza",
      searchTagsText: "Tafuta maneno yanayo endana",

      continueText: "Endelea",
      snackbarText: "",
      searchTagText: "Tafuta Kitambulisho",
      searchText: "Tafuta",
      finishText: "Maliza",
    };
  },

  methods: {
    async initializeData() {
      await this.getShop();
      await this.getDefaultTags();
      await this.checkAccess();
    },
    allowSubmit() {
      if (
        this.name &&
        this.image &&
        this.description &&
        this.tags.length > 0 &&
        !this.loading &&
        this.allow
      ) {
        return true;
      } else {
        return false;
      }
    },
    async checkAccess() {
      this.loading = true;
      const loggedIn = localStorage.loggedIn === "true"; // this.$store.state.loggedIn;
      if (loggedIn && (this.admin() || this.creator() || this.owner()))
        this.allow = true;
      this.loading = false;
    },

    admin() {
      return this.user.is_admin;
    },
    owner() {
      if (this.shop) return this.shop.owner === this.user.id;
    },
    creator() {
      if (this.shop) return this.shop.created_by === this.user.id;
    },
    async searchTags() {
      this.loadingTags = true;
      const cleanQuery = this.query.replace(/[|&;$%@"<>()+,]/g, "").trim();
      let keys = cleanQuery.toLowerCase().split(" ");
      if (keys.length > 10) keys = this.filterKeys(keys);

      const db = firebase.firestore();
      const ref = db
        .collection("Tags")
        .where("keys", "array-contains-any", keys);
      const snapshot = await ref.get().catch((error) => {
        this.loadingTags = false;
        console.log(error);
      });
      const docs = snapshot.docs;
      this.searchResults = [];
      if (docs)
        docs.forEach((doc) => {
          this.searchResults.push(doc.data().title.toLowerCase());
        });
      this.searchResults.push(this.query.toLowerCase().trim());
      this.query = "";
      this.newTag = true;
      this.loadingTags = false;
      this.dialog = false;
    },
    selected(result) {
      return !this.tags.includes(result);
    },
    updateSelected(result) {
      const index = this.tags.indexOf(result);
      if (this.tags.includes(result)) this.tags.splice(index, 1);
      else this.tags.push(result);
      const isNewTag =
        result === this.searchResults[this.searchResults.length - 1];
      if (isNewTag) this.createNewTag(result);
    },
    async createNewTag(tag) {
      if (!tag) return;
      //create new tag
      const db = firebase.firestore();
      const existingTagRef = db.collection("Tags").where("title", "==", tag);
      const snapshot = await existingTagRef.get();
      const tagExists = snapshot.docs.length > 0;
      // const tagAdded = this.tags.includes(tag);
      const createNewTag = !tagExists;
      if (createNewTag) {
        //add a new tag to the db
        const ref = db.collection("Tags");
        const data = {
          title: tag,
          keys: this.getKeys(tag),
          created_at: Date.now(),
        };
        ref.add(data).catch((error) => console.log(error));
      }
    },
    getKeys(tag) {
      const cleanTag = tag.replace(/[^a-zA-Z0-9 ]/g, "");
      let keys = [];
      cleanTag
        .toLowerCase()
        .split(" ")
        .forEach((key) => {
          if (key) keys.push(key);
        });
      return keys;
    },
    async compressImage() {
      const options = {
        maxSizeMB: 0.12,
        maxWidthOrHeight: 1080,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(this.image, options);
        this.image = compressedFile;
      } catch (error) {
        this.loading = false;
        console.log(`error in compressing image ${error}`);
      }
    },
    async submit() {
      this.loading = true;
      const userId = this.$store.state.currentUser.id;
      await this.compressImage();
      const imageUrl = await this.uploadImage();
      await this.setupTags();

      const data = {
        name: this.name,
        description: this.description,
        image_url: imageUrl,
        shop: this.shopId,
        price: this.price,
        tags: this.tags,
        created_by: userId,
        created_at: Date.now(),
      };
      // console.log(data);
      const productId = await this.createProductDoc(data);
      this.loading = false;
      if (productId) this.goToProduct(productId);
    },
    async uploadImage() {
      //   const corsUrl = "https://cors-anywhere.herokuapp.com/";
      const head = window.origin;
      const url = `${head}/app/images/products/`;
      const endpoint = `${url}/upload_image_web.php/`;
      let formData = new FormData();
      formData.append("file", this.image);
      const response = await axios
        .post(`${endpoint}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch((error) => {
          this.loading = false;
          this.error = true;
          this.snackbarText =
            "Kuna hitilafu imetokea kwenye kupakia picha ya bidhaa au huduma, tafadhali jaribu tena";
          console.log(error);
          this.snackbar = true;
        });
      const filename = response.data.filename;
      const imageUrl = `${url}${filename}`;
      return imageUrl;
    },

    async createProductDoc(data) {
      const db = firebase.firestore();
      const ref = db.collection("Products");
      const productRef = await ref.add(data).catch((error) => {
        this.loading = false;
        this.error = true;
        this.snackbarText =
          "Kuna hitilafu imetokea kwenye kutengeneza bidhaa au huduma, tafadhali jaribu tena";
        console.log(error);
        this.snackbar = true;
      });
      return productRef.id;
    },
    async setupTags() {
      await this.createNewTag(this.name);
      if (!this.tags.includes(this.name)) this.tags.push(this.name);
      if (!this.tags.includes(this.shop.name)) this.tags.push(this.shop.name);
      if (!this.tags.includes(this.shop.region))
        this.tags.push(this.shop.region);
      if (!this.tags.includes(this.shop.district))
        this.tags.push(this.shop.district);
    },
    goToProduct(productId) {
      this.$router.replace({ name: "Product", query: { id: productId } });
      this.$gtag.event("Create Product: New Product Successfully Created");
    },
    closeTagsDiaog() {
      this.regionDialog = false;
      this.query = "";
      this.searchResults = [];
    },
    async getDefaultTags() {
      const tags = JSON.parse(localStorage.getItem("tags"));
      if (tags.length > 0) this.searchResults = tags;
      else
        this.searchResults = [
          "Nguo",
          "Fashion",
          "Chakula",
          "Beauty",
          "Urembo",
          "Mtumba",
          "Decor",
          "Electronics",
          "Men",
        ];

      if (this.shop)
        this.shop.tags.forEach((tag) => {
          this.searchResults.push(tag.toLowerCase());
        });
    },
    async getShop() {
      if (!this.shopId) return;
      this.loading = true;
      const db = firebase.firestore();
      const ref = db.collection("Shops").doc(this.shopId);
      const doc = await ref.get().catch((error) => {
        this.loading = false;
        console.log(error);
      });
      if (doc.exists) this.shop = doc.data();
      else {
        this.error = true;
        this.snackbarText =
          "Samahani, kuna hitilafu imetokea, tafadhali jaribu tena";
        this.snackbar = true;
      }
      // console.log(this.shop);

      this.loading = false;
    },
    next(step) {
      this.snackbar = false;
      switch (step) {
        case 1:
          if (this.image) this.currentStep++;
          else {
            this.snackbarText = "Tafadhali Chagua Picha kabla ya kuendelea";
            this.snackbar = true;
          }
          break;
        case 2:
          if (this.name && this.description) {
            this.currentStep++;
            this.getDescriptionTags();
          } else {
            this.snackbarText =
              "Tafadhali weka jina la bidhaa na maelezo mafupi";
            this.snackbar = true;
          }
          break;
      }
    },

    async getDescriptionTags() {
      const keys = this.description
        .replace(/[^a-zA-Z0-9 ]/g, "")
        .trim()
        .toLowerCase()
        .split(" ");

      const db = firebase.firestore();
      const ref = db
        .collection("Tags")
        .where("keys", "array-contains-any", this.filterKeys(keys));
      const snapshot = await ref.get().catch((error) => console.log(error));
      const docs = snapshot.docs;
      if (docs.length === 0) return;
      docs.forEach((doc) => {
        this.searchResults.push(doc.data().title.toLowerCase());
      });
    },
    filterKeys(keys) {
      let longKeys = [];
      keys.forEach((key) => {
        if (key.length > 2 && !longKeys.includes(key)) longKeys.push(key);
      });
      longKeys.sort(() => Math.random() - 0.5);

      if (longKeys.length < 10) return longKeys;
      else {
        longKeys.splice(10, longKeys.length - 10);
        return longKeys;
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "currentUser",
    }),
    checkTags() {
      if (this.tags.length !== 0) {
        let tagsString = "";
        this.tags.forEach((tag) => {
          tagsString += `${tag}, `;
        });

        return tagsString;
      } else return this.selectTagsText;
    },
    selectedImage() {
      if (this.image) return URL.createObjectURL(this.image);
      else return "";
    },
  },
};
</script>