<template>
  <v-container id="search-section" class="my-1">
    <v-row justify="center">
      <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 10">
        <v-row justify="center" align="center">
          <v-text-field
            rounded
            dense
            filled
            :label="searchText"
            :placeholder="placeholderText"
            v-model="query"
            :hint="showHint"
            v-on:keyup.enter="goToSearch"
          >
            <v-icon
              name="Search Button"
              aria-label="Search Button"
              slot="append"
              color="primary"
              @click="goToSearch"
            >
              mdi-magnify
            </v-icon>
          </v-text-field>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "SearchSection",
  data() {
    return {
      searchText: "Tafuta Maduka, Bidhaa, na Huduma",
      hints: [],
      query: "",
      shortQuery: false,
      errorMessage: "Tafadhali tafuta kitu chenye herufi zaidi ya mbili",
      placeholderText:
        "Nguo, Fashion, Chakula, Afya, Saa, Vinywaji, Electronics...",
    };
  },

  methods: {
    goToSearch() {
      if (this.query.length < 3) {
        this.shortQuery = true;
        return;
      }
      this.$router.push({ name: "Search", query: { q: this.query } });
      this.$gtag.event("Search", { query: this.query });
      this.query = "";
      // if (this.$router.currentRoute.name === "Search") {
      //   location.reload();
      // }
    },
  },
  computed: {
    showHint() {
      return this.shortQuery ? this.errorMessage : undefined;
    },
  },
};
</script>

