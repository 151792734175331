<template>
  <div id="error-view">
    <v-row justify="center" align="center" class="fill-height ma-6">
      <v-col :cols="$vuetify.breakpoint.mobile ? 8 : 4">
        <v-img
          :src="errorImage"
          :width="$vuetify.breakpoint.mobile ? '100vw' : '100%'"
          max-height="50vh"
          contain
          transition="scale-transition"
        ></v-img>
        <p class="d-flex-center text-center ma-auto py-4">{{ getMessage }}</p>

        <v-row justify="center">
          <v-btn
            rounded
            class="primary text-capitalize"
            elevation="8"
            @click="refresh"
            >{{ refreshText }}</v-btn
          >
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ErrorView",
  props: { message: String },
  data() {
    return {
      refreshText: "Jaribu Tena",
    };
  },
  computed: {
    getMessage() {
      if (!this.message)
        return "Samahani kuna hitlafu imetokea, tafadhali jaribu tena.";
      else return this.message;
    },

    errorImage() {
      const images = [
        require("@/assets/images/img_error_01.svg"),
        require("@/assets/images/img_error_02.svg"),
        require("@/assets/images/img_error_03.svg"),
        require("@/assets/images/img_error_04.svg"),
      ];
      images.sort(() => Math.random() - 0.5);
      return images[0];
    },
  },

  methods: {
    refresh() {
      location.reload();
    },
  },
};
</script>
