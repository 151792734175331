<template>
  <div id="search">
    <h2 class="d-flex-center text-center ma-auto py-4">{{ searchTitle }}</h2>
    <SearchSection />
    <LoadingView v-if="loading" />
    <ErrorView v-if="error" />
    <v-container v-if="!query" grid-list-xs>
      <v-row justify="center" align="center">
        <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 6">
          <SearchRecommendations />
          <v-img
            :src="image"
            contain
            max-height="50vh"
            :width="$vuetify.breakpoint.mobile ? '100vw' : '100%'"
            transition="scale-transition"
          ></v-img>
          <p class="d-flex-center text-center ma-auto py-4">
            {{ searchMessage }}
          </p>
          <v-row justify="center">
            <v-btn
              large
              rounded
              color="primary"
              class="text-capitalize"
              @click="goToRecommendations"
              >{{ recommendationsText }}</v-btn
            >
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <EmptyView v-if="query && !loading && noTags" :message="noTagsMessage" />
    <v-container v-else grid-list-xs>
      <v-row justify="center">
        <v-chip
          v-for="(tag, i) in tags"
          :key="i"
          class="primary ma-2"
          @click="goToTag(tag.data().title)"
        >
          {{ tag.data().title }}
        </v-chip>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// import firebase from "firebase";
import firebase from "firebase/compat/app";
import LoadingView from "@/components/layouts/LoadingView.vue";
import EmptyView from "@/components/layouts/EmptyView.vue";
import ErrorView from "@/components/layouts/ErrorView.vue";
import SearchSection from "@/components/layouts/SearchSection.vue";
import SearchRecommendations from "@/components/search/SearchRecommendations.vue";

export default {
  name: "Search",
  components: {
    LoadingView,
    EmptyView,
    ErrorView,
    SearchSection,
    SearchRecommendations,
  },
  data() {
    return {
      query: "",
      tags: [],
      loading: true,
      noTagsMessage: "Samahani, tumeshidwa kupata unachotafuta",
      error: false,
      image: require("@/assets/images/img_online_shopping.svg"),
      searchMessage: "Karibu kutafuta Maduka, Bidhaa na Huduma mbalimbali",
      searchTitle: "Tafuta Maduka, Bidhaa na Huduma",
      recommendations: [],
      recommendationsText: "Mapendekezo",
    };
  },
  mounted() {
    this.setup();
  },
  methods: {
    setup() {
      this.query = this.$router.currentRoute.query.q;
      if (this.query) this.search();
      else this.loading = false;
    },
    async search() {
      this.loading = true;

      const db = firebase.firestore();
      const cleanQuery = this.query.replace(/[^a-zA-Z0-9 ]/g, "");
      const keys = cleanQuery.toLowerCase().split(" ");
      const fileteredKeys = this.filterKeys(keys);
      const ref = db
        .collection("Tags")
        .where("keys", "array-contains-any", fileteredKeys);
      const snapshot = await ref.get().catch((error) => {
        this.error = true;
        console.log(error);
      });
      this.tags = snapshot.docs;
      this.loading = false;
      this.$gtag.event("Search Query", { query: this.query });
    },
    goToTag(tag) {
      this.$router.push({ name: "Tag", query: { tag: tag } });
      this.$gtag.event("Search: Tag", { tag: tag });
    },
    goToRecommendations() {
      this.$router.push({ name: "Recommendations" });
      this.$gtag.event("Search: Load more Button");
    },
    filterKeys(keys) {
      let fileteredKeys = [];
      keys.forEach((key) => {
        if (key.length > 2) fileteredKeys.push(key);
      });
      return fileteredKeys;
    },

    getMeta() {
      return {
        title: this.query + " | MadukaYetu",
        titleTemplate: "%s - MadukaYetu",
        htmlAttrs: {
          lang: "en-US",
        },
        meta: [
          { charset: "utf-8" },
          {
            name: "og:title",
            content: this.query + " | MadukaYetu",
          },
          {
            name: "description",
            vmid: "description",
            content: "Bidhaa za " + this.query + " kwenye MadukaYetu",
          },
          {
            name: "og:description",
            content: "Bidhaa za " + this.query + " kwenye MadukaYetu",
          },
          {
            name: "og:url",
            content:
              window.location.origin + this.$router.currentRoute.fullPath,
          },
          {
            name: "og:image",
            content: "https://madukayetu.co.tz/app/icons/Icon-192.png",
          },
          { name: "viewport", content: "width=device-width, initial-scale=1" },
        ],
      };
    },
    defaultMeta() {
      return {
        title: "Tafuta | Search",
        titleTemplate: "%s - MadukaYetu",
        htmlAttrs: {
          lang: "en-US",
        },
        meta: [
          { charset: "utf-8" },

          {
            name: "description",
            vmid: "description",
            content: "Tafuta Maduka, Bidhaa na Huduma mbalimbali MadukaYetu",
          },
          {
            name: "og:description",
            content: "Tafuta Maduka, Bidhaa na Huduma mbalimbali MadukaYetu",
          },
          {
            name: "og:url",
            content:
              window.location.origin + this.$router.currentRoute.fullPath,
          },
          {
            name: "og:image",
            content: "https://madukayetu.co.tz/app/icons/Icon-192.png",
          },
        ],
      };
    },
  },
  computed: {
    noTags() {
      return this.tags.length == 0;
    },
  },
  watch: {
    $route(to, from) {
      const currentQuery = from.query.q;
      const toQuery = to.query.q;
      if (currentQuery !== toQuery) this.setup();
    },
  },

  metaInfo() {
    if (this.query) return this.defaultMeta();
    else return this.getMeta();
  },
};
</script>

