<template>
  <div id="saved-shops">
    <v-container>
      <h1 class="text-center">{{ savedShopsText }}</h1>
      <v-container grid-list-xs v-if="!loading && !error">
        <EmptyView v-if="!loading && noShops" :message="emptyShopsText" />
        <v-row v-else justify-lg="center">
          <v-col v-for="shop in shops" :key="shop.id" cols="6" lg="3">
            <ShopCard :shop="shop" />
          </v-col>
          <LoadMoreButton
            v-show="hasMore"
            :loading="loadingMore"
            @loadmore="loadMore"
          />
        </v-row>
      </v-container>
      <LoadingView v-if="loading" />
      <ErrorView v-if="error" />
    </v-container>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import ShopCard from "@/components/layouts/ShopCard.vue";
import LoadingView from "@/components/layouts/LoadingView.vue";
import EmptyView from "@/components/layouts/EmptyView.vue";
import ErrorView from "@/components/layouts/ErrorView.vue";
import LoadMoreButton from "@/components/layouts/LoadMoreButton.vue";

export default {
  name: "SavedShops",
  components: {
    ShopCard,
    LoadingView,
    EmptyView,
    ErrorView,
    LoadMoreButton,
  },
  data() {
    return {
      loading: false,
      error: false,
      shops: [],
      loadingMore: false,
      loadLimit: 12,
      hasMore: false,
      savedShopsText: "Maduka Niliyohifadhi",
      emptyShopsText: "Hakuna maduka uliyohifadhi",
      lastDoc: null,
    };
  },
  mounted() {
    this.getSavedShops();
  },
  computed: {
    noShops() {
      return this.shops.length === 0;
    },
  },
  methods: {
    async getSavedShops() {
      try {
        this.loading = true;
        const db = firebase.firestore();
        const userId = this.$store.state.currentUser.id;
        const ref = db
          .collection("Users")
          .doc(userId)
          .collection("SavedShops")
          .limit(this.loadLimit);
        const snapshot = await ref.get();
        const docs = snapshot.docs;
        const hasShops = docs.length > 0;
        if (!hasShops) {
          this.loading = false;
          return;
        }
        this.hasMore = docs.length === this.loadLimit;
        this.lastDoc = docs[docs.length - 1];
        await this.getShops(db, docs);
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.error = true;
        this.loading = false;
      }
    },
    async getShops(db, docs) {
      docs.forEach(async (savedDoc) => {
        const ref = db.collection("Shops").doc(savedDoc.id);
        const doc = await ref.get().catch((error) => console.log(error));
        this.shops.push(doc);
      });
    },
    async loadMore() {
      this.loadingMore = true;
      try {
        const db = firebase.firestore();
        const userId = this.$store.state.currentUser.id;
        const ref = db
          .collection("Users")
          .doc(userId)
          .collection("SavedShops")
          .limit(this.loadLimit);
        const snapshot = await ref.startAfter(this.lastDoc).get();
        const docs = snapshot.docs;
        this.hasMore = docs.length === this.loadLimit;
        this.lastDoc = docs[docs.length - 1];
        await this.getShops(db, docs);
      } catch (error) {
        console.log(error);
        this.error = true;
        this.loadingMore = false;
      }
      this.loadingMore = false;
    },
  },
};
</script>



