<template>
  <div id="user-profile-button">
    <v-btn icon x-large @click="sheet = !sheet">
      <v-avatar size="36">
        <v-img :src="user['image_url']" :alt="userProfilePhotoText"></v-img>
      </v-avatar>
    </v-btn>
    <v-bottom-sheet v-model="sheet" inset scrollable>
      <v-list color="white rounded-lg">
        <v-list-item @click="goToProfile">
          <v-list-item-avatar>
            <v-img :src="user.image_url"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ user.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="goToProfile">
          <v-list-item-avatar color="primary">
            <v-icon color="white"> mdi-account </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ myAccountText }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
        <v-subheader>{{ myShopsText }}</v-subheader>
        <MyShopsList @finish="sheet = !sheet" />
      </v-list>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MyShopsList from "@/components/layouts/MyShopsList.vue";

export default {
  components: { MyShopsList },
  name: "UserProfileButton",
  data() {
    return {
      user: this.$store.state.currentUser,
      sheet: false,
      myAccountText: "Akaunti Yangu",
      myShopsText: "Maduka Yangu",
      userProfilePhotoText: "User Profile Photo",
    };
  },
  computed: {
    ...mapGetters({
      userShops: "userShops",
    }),
  },
  methods: {
    goToShop(shop) {
      this.$router.push({ name: "Shop", query: { id: shop.id } });
      this.$gtag.event("My Shop");
    },
    goToProfile() {
      this.sheet = false;
      this.$router.push({ name: "UserProfile" });
    },
  },
};
</script>

