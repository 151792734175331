<template>
  <div id="save-shop-button" class="mx-4">
    <v-sheet width="20px" color="white">
      <v-progress-circular
        v-if="loading"
        indeterminate
        color="primary"
        size="24"
      ></v-progress-circular>
      <v-icon
        v-if="!loading"
        aria-label="Save Shop Button"
        color="primary"
        @click="save"
        >{{ icon }}</v-icon
      >
    </v-sheet>
    <v-snackbar v-model="snackbar" timeout="2000" rounded="pill">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          rounded
          v-bind="attrs"
          to="/savedshops"
          class="text-capitalize"
        >
          {{ openText }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";

export default {
  name: "SaveShopButton",
  data() {
    return {
      saved: false,
      loading: false,
      snackbar: false,
      snackbarText: "Duka limehifadhiwa",
      openText: "Fungua",
    };
  },
  mounted() {
    this.getStatus();
  },
  computed: {
    icon() {
      if (this.saved) return "mdi-bookmark";
      else return "mdi-bookmark-multiple-outline";
    },
  },
  methods: {
    getRef() {
      const db = firebase.firestore();
      const userId = this.$store.state.currentUser.id;
      const shopId = this.$router.currentRoute.query.id;
      return db.doc(`Users/${userId}/SavedShops/${shopId}`);
    },
    async getStatus() {
      this.loading = true;
      const ref = this.getRef();
      const doc = await ref.get().catch((error) => {
        this.loading = false;
        console.log(error);
      });
      this.saved = doc.exists;
      this.loading = false;
    },
    async save() {
      if (!this.$store.state.loggedIn) {
        this.$router.push({ name: "Login" });
        return;
      }
      this.loading = true;
      try {
        const ref = this.getRef();
        if (this.saved) await ref.delete();
        else ref.set({ created_at: Date.now() });
        await this.getStatus();
        if (this.saved) this.snackbar = true;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

