<template>
  <v-col lg="8" class="mx-auto">
    <h2 class="text-center ml-lg-4 mb-2">
      {{ productsText }}
    </h2>

    <v-row class="mx-lg-1 mb-lg-1" justify="center" align="center">
      <v-col
        v-for="product in products"
        :key="product.id"
        :cols="$vuetify.breakpoint.mobile ? 6 : 3"
      >
        <ProductCard :product="product" />
      </v-col>
    </v-row>

    <v-row justify="center" align="center">
      <v-col>
        <EmptyView v-if="noProducts" :message="noProductsText" />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import firebase from "firebase/compat/app";
import EmptyView from "@/components/layouts/EmptyView.vue";
import ProductCard from "@/components/layouts/ProductCard.vue";
export default {
  name: "ShopProducts",
  components: {
    EmptyView,
    ProductCard,
  },
  data() {
    return {
      shopId: "",
      products: null,
      loading: true,
      noProductsText: "Duka hili bado halija weka bidhaa na huduma",
      productsText: "Bidhaa na Huduma",
    };
  },
  methods: {
    async getProducts() {
      this.loading = true;

      if (this.shopId) {
        const db = firebase.firestore();
        const snapshot = await db
          .collection("Products")
          .where("shop", "==", this.shopId)
          .get()
          .catch((error) => console.log(error));
        const docs = snapshot.docs;
        this.products = docs;
        this.loading = false;
      }
    },

    goToProduct(productId) {
      this.$router.push({ name: "Product", query: { id: productId } });
      window.scrollTo(0, 0);
    },
    shortDescription(description) {
      if (description.length > 100)
        return `${description.substring(0, 100)}...`;
      else return description;
    },
    userHasAccess() {},
  },
  mounted() {
    this.shopId = this.$router.currentRoute.query.id;
    this.getProducts();
  },
  computed: {
    noProducts() {
      if (this.products != null) return this.products.length == 0;
      else return false;
    },
  },
};
</script>

