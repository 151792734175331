<template>
  <v-footer app padless absolute dark hide-on-scroll>
    <v-card flat tile width="100%" class="black lighten-1 text-center">
      <v-card-text class="black lighten-1" dark>
        <v-row
          :justify="$vuetify.breakpoint.mobile ? 'center' : 'start'"
          align="center"
          class="mr-2"
        >
          <a :href="aboutUrl" class="text-decoration-none">
            <v-btn plain class="text-capitalize">{{ aboutText }}</v-btn>
          </a>
          <v-btn plain class="text-capitalize" @click="sheet = !sheet">{{
            helpText
          }}</v-btn>
          <a :href="termsUrl" class="text-decoration-none">
            <v-btn plain class="text-capitalize">{{ termsText }}</v-btn>
          </a>
          <v-spacer v-if="!$vuetify.breakpoint.mobile"></v-spacer>
          <p class="caption my-1">©{{ this.getYear }} MadukaYetu v{{ appVersion }}</p>
        </v-row>
      </v-card-text>
    </v-card>

    <v-bottom-sheet v-model="sheet" inset dark>
      <v-list class="black">
        <v-list-item
          v-for="(contact, i) in contacts"
          :key="i"
          two-line
          @click="open(contact.url)"
        >
          <v-avatar tile>
            <v-icon small color="white">
              {{ `mdi-${contact.icon}` }}
            </v-icon>
          </v-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ contact.detail }}</v-list-item-title>
            <v-list-item-subtitle>{{ contact.title }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-bottom-sheet>
  </v-footer>
</template>

<script>
import { version } from "@/../package";
export default {
  name: "Footer",
  data() {
    return {
      aboutText: "About",
      helpText: "Help",
      termsText: "Terms",
      aboutUrl: "https://about.madukayetu.co.tz",
      termsUrl: "https://about.madukayetu.co.tz/terms",
      sheet: false,
      appVersion: version,

      contacts: [
        {
          title: "Phone",
          detail: "+255622022710",
          icon: "phone",
          url: "tel:+255622022710",
        },
        {
          title: "Email",
          detail: "karibu@madukayetu.co,tz",
          icon: "email",
          url: "mailto: karibu@madukayetu.co,tz",
        },
        {
          title: "WhatsApp",
          detail: "+255622022710",
          icon: "whatsapp",
          url: "https://wa.me/+255622022710",
        },
        {
          title: "Twitter",
          detail: "madukayetu",
          icon: "twitter",
          url: "https://www.twitter.com/madukayetu",
        },
        {
          title: "Facebook",
          detail: "madukayetu",
          icon: "facebook",
          url: "https://www.facebook.com/madukayetu",
        },
        {
          title: "Instagram",
          detail: "madukayetu",
          icon: "instagram",
          url: "https://www.instagram.com/madukayetu",
        },

        {
          title: "Website",
          detail: "madukayetu.co.tz",
          icon: "web",
          url: "https://madukayetu.co.tz",
        },
      ],
    };
  },
  computed: {
    getYear() {
      return new Date().getFullYear();
    },
  },
  methods: {
    open(url) {
      window.open(url);
      this.sheet = false;
      this.$gtag.event("Footer Buttons", { url: url });
    },
  },
};
</script>

