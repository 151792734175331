<template>
  <div id="new" class="white">
    <h1 class="text-center mt-2 mx-2">{{ newArrivalsText }}</h1>
    <v-tabs v-model="tab" background-color="white" color="primary" centered>
      <v-tab v-for="tab in tabs" :key="tab.key" class="text-capitalize">
        {{ tab.title }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="tab in tabs" :key="tab.key">
        <NewItems :tab="tab.key" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import NewItems from "@/components/newarrivals/NewItems.vue";
export default {
  name: "New",
  components: { NewItems },
  data() {
    return {
      newArrivalsText: "Bidhaa na Maduka Mapya",
      tabs: [
        { title: "Huduma na Bidhaa", key: "products" },
        { title: "Maduka", key: "shops" },
      ],
      tab: null,
    };
  },
};
</script>

