<template>
  <div id="login-button">
    <UserProfileButton v-if="loggedIn && !error" />
    <router-link to="/login">
      <v-icon v-if="!loggedIn || error" aria-label="Login Button"
        >mdi-account</v-icon
      >
    </router-link>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import UserProfileButton from "@/components/layouts/UserProfileButton.vue";

export default {
  name: "LoginButton",
  components: { UserProfileButton },
  data() {
    return {
      user: {},
      sheet: false,
      loggedIn: false,
      error: false,
    };
  },
  methods: {
    async logout() {
      const auth = firebase.auth();
      await auth.signOut();
      await this.checkUser();
    },
    async checkUser() {
      const auth = firebase.auth();
      auth.onAuthStateChanged(async (firebaseUser) => {
        if (firebaseUser) {
          await this.getUser(firebaseUser);
          this.$store.commit("updateCurrentUser", this.user);
          this.$store.commit("updateLoginStatus", true);
          localStorage.loggedIn = true;
          this.user = this.$store.state.currentUser;
          this.loggedIn = this.$store.state.loggedIn;
          this.checkHasShop();
        } else {
          this.$store.commit("updateLoginStatus", false);
          this.user = this.$store.state.currentUser;
          this.loggedIn = this.$store.state.loggedIn;
          localStorage.loggedIn = false;
        }
      });
    },
    async getUser(user) {
      const id = user.uid;
      const db = firebase.firestore();
      const ref = db.collection("Users").doc(id);
      const doc = await ref.get().catch((error) => {
        this.error = true;
        console.log(error);
      });

      if (doc.exists) {
        this.user = doc.data();
        this.user.id = id;
      } else await this.createNewUser(user);
    },
    async createNewUser(user) {
      const db = firebase.firestore();
      const ref = db.collection("Users").doc(user.uid);
      const data = {
        id: user.uid,
        name: user.displayName,
        image_url: user.photoURL,
        email: user.email,
        created_at: Date.now(),
        is_admin: false,
      };

      await ref
        .set(data)
        .catch((error) => console.log(`login error: ${error}`));

      const doc = await ref
        .get()
        .catch((error) => console.log(`error fetching new user doc: ${error}`));
      this.user = doc.data();
      this.user.id = user.uid;
    },
    async checkHasShop() {
      const db = firebase.firestore();
      const creatorRef = db
        .collection("Shops")
        .where("created_by", "==", this.user.id);
      const snapshot = await creatorRef
        .get()
        .catch((error) => console.log(error));
      const creatorDocs = snapshot.docs;
      const ownerRef = db
        .collection("Shops")
        .where("owner", "==", this.user.id);
      const ownerSnapshot = await ownerRef
        .get()
        .catch((error) => console.log(error));
      const ownerDocs = ownerSnapshot.docs;
      const shopsDocs = this.parseDocs(creatorDocs, ownerDocs);

      const hasShop = shopsDocs.length > 0;
      localStorage.hasShop = hasShop;
      if (hasShop) this.$store.commit("updateUserShops", shopsDocs);
    },

    parseDocs(creatorDocs, ownerDocs) {
      let creatorIds = [];
      let shopsDocs = creatorDocs;
      creatorDocs.forEach((doc) => {
        creatorIds.push(doc.id);
      });
      if (ownerDocs.length > 0) {
        ownerDocs.forEach((doc) => {
          if (!creatorIds.includes(doc.id)) shopsDocs.push(doc);
        });
      }
      return shopsDocs;
    },
  },
  mounted() {
    this.checkUser();
  },
};
</script>

