<template>
  <v-row class="fill-height ma-0" align="center" justify="center">
    <v-avatar size="48" color="primary">
      <v-img
        :src="logo"
        :alt="alt"
        rounded="pill"
        contain
        max-height="36"
      ></v-img>
    </v-avatar>
    <!-- <v-progress-circular indeterminate color="primary lighten-5"></v-progress-circular> -->
  </v-row>
</template>

<script>
export default {
  name: "Placeholder",
  data() {
    return {
      logo: require("@/assets/icons/logo_1.svg"),
      alt: "MadukaYetu, Placeholder Logo",
    };
  },
};
</script>

