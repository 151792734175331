<template>
  <div id="related-shops">
    <v-col>
      <h3 class="mb-4">{{ realatedShopsText }}</h3>
      <v-row justify="center" align="center">
        <v-col v-for="(shop, i) in relatedShops" :key="i" cols="6" lg="4">
          <ShopCard :shop="shop" />
        </v-col>
      </v-row>
      <LoadMoreButton v-if="hasMore" :loading="loading" @loadmore="loadMore" />
    </v-col>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import ShopCard from "@/components/layouts/ShopCard.vue";
import LoadMoreButton from "@/components/layouts/LoadMoreButton.vue";

export default {
  name: "RelatedShops",
  components: { ShopCard, LoadMoreButton },
  props: {
    shop: Object,
  },
  mounted() {
    this.getShops();
  },
  data() {
    return {
      realatedShopsText: "Maduka Yanayoendana",
      relatedShops: [],
      loadLimit: 12,
      hasMore: false,
      loading: false,
      error: false,
    };
  },
  methods: {
    async getShops() {
      if (!this.shop) return;
      const tags = this.shop.tags;
      if (!tags) return;
      const filteredTags = this.filterTags(tags);

      const db = firebase.firestore();
      const ref = db
        .collection("Shops")
        .where("tags", "array-contains-any", filteredTags)
        .limit(this.loadLimit);

      const snapshot = await ref.get().catch((error) => {
        console.log(error);
        this.loading = false;
        this.error = true;
      });
      const docs = snapshot.docs;
      this.hasMore = docs.length === this.loadLimit;
      this.lastDoc = docs[docs.length - 1];
      docs.sort(() => Math.random() - 0.5);
      this.relatedShops = docs;
      this.loading = false;
    },

    async loadMore() {
      this.loading = true;
      const db = firebase.firestore();
      const filteredTags = this.filterTags(this.shop.tags);

      const ref = db
        .collection("Shops")
        .where("tags", "array-contains-any", filteredTags)
        .limit(this.loadLimit)
        .startAfter(this.lastDoc);

      const snapshot = await ref.get().catch((error) => {
        this.error = true;
        this.loading = false;
        console.log(error);
      });

      const docs = snapshot.docs;
      this.hasMore = docs.length === this.loadLimit;
      this.lastDoc = docs[docs.length - 1];
      docs.sort(() => Math.random() - 0.5);
      docs.forEach((doc) => {
        this.relatedShops.push(doc);
      });

      this.loading = false;
    },

    filterTags(tags) {
      if (tags && tags.length > 10) {
        tags.sort(() => Math.random() - 0.5);
        tags.splice(10, tags.length - 10);
        return tags;
      } else return tags;
    },
  },
};
</script>

