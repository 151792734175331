<template>
  <div id="comment-button" class="mx-4">
    <v-btn icon @click="dialog = !dialog"
      ><v-icon color="primary">mdi-comment-multiple-outline</v-icon>
    </v-btn>
    <v-dialog
      v-model="dialog"
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card color="white">
        <v-card-title primary-title>
          {{ commentText }}
        </v-card-title>
        <v-card-text v-if="!loggedIn">
          <v-row justify="center" class="my-4">
            <v-btn
              rounded
              class="text-capitalize"
              to="/login"
              color="primary"
              >{{ loginText }}</v-btn
            >
          </v-row>
        </v-card-text>
        <v-card-text v-else>
          <v-text-field
            v-model="text"
            name="comment"
            :label="commentText"
            clearable
            :loading="loading"
          ></v-text-field>
          <v-card-actions>
            <v-row justify="end">
              <v-btn
                text
                rounded
                color="grey"
                @click="dialog = !dialog"
                class="text-capitalize mx-2"
                >{{ cancelText }}</v-btn
              >
              <v-btn
                outlined
                rounded
                color="primary"
                @click="submit"
                class="text-capitalize ml-2"
                >{{ submitText }}</v-btn
              >
            </v-row>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      timeout="2000"
      :color="error ? 'error' : 'success'"
    >
      {{ snackbarText }}
    </v-snackbar>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
export default {
  data() {
    return {
      dialog: false,
      snackbar: false,
      commentText: "Comment",
      text: "",
      cancelText: "Cancel",
      submitText: "Submit",
      loading: false,
      snackbarText: "Comment Submitted",
      error: false,
      loggedIn: localStorage.loggedIn === "true",
      loginText: "Login to comment",
    };
  },
  methods: {
    async submit() {
      if (!this.text) return;
      this.loading = true;
      try {
        const db = firebase.firestore();
        const collectionName = this.getCollection();
        const id = this.$router.currentRoute.query.id;
        
        const ref = db.collection(`${collectionName}/${id}/Comments/`);
        const data = {
          text: this.text,
          author: this.$store.state.currentUser.name,
          created_by: this.$store.state.currentUser.id,
          author_image_url: this.$store.state.currentUser.image_url,
          created_at: Date.now(),
        };
        await ref.add(data);
        this.loading = false;
        this.dialog = false;
        this.snackbarText = "Comment submitted";
        this.snackbar = true;
      } catch (error) {
        console.log("error", error), (this.loading = false);
        this.error = true;
        this.snackbarText = "Something went wrong, please try again";
        this.snackbar = true;
      }
    },
    getCollection() {
      const pageName = this.$router.currentRoute.name;
      switch (pageName) {
        case "Product":
          return "Products";
        case "Shop":
          return "Shops";
        default:
          return "";
      }
    },
  },
};
</script>
