<template>
  <v-btn
    outlined
    rounded
    color="black"
    class="text-capitalize"
    @click="$emit('next')"
    >{{ continueText }}</v-btn
  >
</template>

<script>
export default {
  name: "ContinueButton",
  data() {
    return {
      continueText: "Endelea",
    };
  },
};
</script>

