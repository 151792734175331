<template>
  <div id="product-cards">
    <LoadingView v-if="loading" :loading="loading" />
    <v-row v-else justify="center" align="center">
      <v-col v-for="(product, i) in products" :key="i" lg="3" cols="6">
        <ProductCard :product="product" />
      </v-col>
      <LoadMoreButton
        v-show="hasMore"
        :loading="loadingMore"
        @loadmore="getData"
      />
    </v-row>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import ProductCard from "@/components/layouts/ProductCard.vue";
import LoadMoreButton from "@/components/layouts/LoadMoreButton.vue";
import LoadingView from "@/components/layouts/LoadingView.vue";

export default {
  name: "ProductCards",
  components: {
    ProductCard,
    LoadMoreButton,
    LoadingView,
  },
  data() {
    return {
      products: [],
      lastDoc: null,
      loadLimit: 20,
      loading: false,
      loadingMore: false,
      tags: [],
      loadRandom: false,
      hasMore: false,
    };
  },

  methods: {
    getTags() {
      this.tags = JSON.parse(localStorage.getItem("tags"));
    },
    getData() {
      const ref = this.getRef();
      if (this.lastDoc == null) {
        this.loadNew(ref);
      } else {
        this.loadMore(ref);
      }
    },
    getRef() {
      var db = firebase.firestore();
      let ref;
      if (this.tags && !this.loadRandom) {
        if (this.tags.length > 10) {
          this.tags.sort(() => Math.random() - 0.5);
          const filteredTags = this.tags;
          filteredTags.splice(10, this.tags.length - 10);
          ref = db
            .collection("Products")
            .where("tags", "array-contains-any", filteredTags)
            .limit(this.loadLimit);
        } else
          ref = db
            .collection("Products")
            .where("tags", "array-contains-any", this.tags)
            .limit(this.loadLimit);
      } else ref = db.collection("Products").limit(this.loadLimit);
      return ref;
    },
    async loadNew(ref) {
      this.loading = true;
      const snapshot = await ref
        .get()
        .catch((error) => console.log("error on getting shops: " + error));
      const docs = snapshot.docs;
      if (docs.length < 10) this.loadRandom = true;
      this.lastDoc = docs[docs.length - 1];
      docs.sort(() => Math.random() - 0.5);
      this.products = docs;
      this.loading = false;
      this.hasMore = docs.length > this.loadLimit / 3;
    },
    async loadMore(ref) {
      this.loadingMore = true;
      const snapshot = await ref
        .startAfter(this.lastDoc)
        .get()
        .catch((error) =>
          console.log("error on loading more products: " + error)
        );
      const docs = snapshot.docs;
      if (docs.length < 10) this.loadRandom = true;
      this.lastDoc = docs[docs.length - 1];
      docs.sort(() => Math.random() - 0.5);
      docs.forEach((doc) => this.products.push(doc));
      this.loadingMore = false;
      this.hasMore = docs.length > this.loadLimit / 3;
      this.$gtag.event("Home: Load more Button");
    },
  },
  mounted() {
    this.getTags();
    this.getData();
  },
};
</script>