<template>
  <div id="loading-view" class="white">
    <v-container grid-list-xs class="white" >
      <v-row justify="center" align="center">
        <v-col :cols="$vuetify.breakpoint.mobile ? 8 : 4">
          <v-img
            :src="loadingImage"
            contain
            max-height="50vh"
            transition="scale-transition"
            :width="$vuetify.breakpoint.mobile ? '100vw' : '100%'"
          ></v-img>
          <p class="d-flex-center text-center ma-auto py-4">{{ getMessage }}</p>
          <v-row justify="center" class="ma-6">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "LoadingView",
  props: {
    loading: Boolean,
  },
  computed: {
    loadingImage() {
      const images = [
        require("@/assets/images/img_waiting_01.svg"),
        require("@/assets/images/img_waiting_02.svg"),
        require("@/assets/images/img_waiting_04.svg"),
        require("@/assets/images/img_waiting_05.svg"),
        require("@/assets/images/img_waiting_06.svg"),
      ];
      images.sort(() => Math.random() - 0.5);
      return images[0];
    },
    getMessage() {
      if (this.message) return this.message;
      else {
        const messages = [
          "Haba na haba hujaza kibaba",
          "Tuko tunapanga mambo",
          "Subiri kidogo",
          "Please wait",
          "Simba mwenda pole, ndie mla nyama",
          "Tunaenda kwa mwendo wa paka",
          "Haraka haraka haina baraka",
        ];
        messages.sort(() => Math.random() - 0.5);
        return messages[0];
      }
    },
  },
  prop: { message: String },
};
</script>

