<template>
  <div id="notfound-view">
    <v-row justify="center" align="center" class="fill-height ma-6">
      <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 6">
        <v-img
          :src="errorImage"
          :width="$vuetify.breakpoint.mobile ? '100vw' : '100%'"
          max-height="50vh"
          contain
          transition="scale-transition"
        ></v-img>
        <p class="d-flex-center text-center ma-auto py-4">{{ message }}</p>

        <v-row justify="center">
          <v-btn
            rounded
            class="primary text-capitalize"
            elevation="8"
            @click="goHome"
            >{{ homeText }}</v-btn
          >
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  data() {
    return {
      errorImage: require("@/assets/images/img_404.svg"),
      homeText: "Rudi Mwanzo",
      message: "Huu ukurasa haupo kwa sasa, unaweza rudi mwanzoni.",
    };
  },
  methods: {
    goHome() {
      this.$router.push({ name: "Home" });
    },
  },
};
</script>

