<template>
  <div id="share-button" class="mx-4">
    <v-icon aria-label="Share Button" color="primary" @click="share"
      >mdi-checkbox-multiple-blank-outline</v-icon
    >

    <v-snackbar v-model="snackbar" timeout="2000" rounded="pill">
      {{ snackbarText }}
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "ShareDialog",

  data() {
    return {
      url: "",
      snackbar: false,
      snackbarText: "Link iko tayari",
    };
  },

  methods: {
    async share() {
      this.url = location.href;
      navigator.clipboard.writeText(this.url);
      this.snackbar = true;
    },
  },
};
</script>
