<template>
  <div id="recommendations">
    <v-container grid-list-xs>
      <h1 class="text-center">{{ recommendationsText }}</h1>
      <v-row justify="center">
        <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 5">
          <h3 class="text-center">{{ followingText }}</h3>
          <v-row class="px-1 my-3" justify="center">
            <router-link
              v-for="tag in tags"
              :key="tag"
              :to="{ name: 'Tag', query: { tag: tag } }"
            >
              <v-chip color="primary" class="ma-1">{{ tag }}</v-chip>
            </router-link>
          </v-row>
        </v-col>

        <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 5">
          <h3 class="text-center">{{ recommendationsText }}</h3>
          <v-row class="px-1 my-3" justify="center">
            <router-link
              v-for="tag in relatedTags"
              :key="tag"
              :to="{ name: 'Tag', query: { tag: tag } }"
              class="text-decoration-none"
            >
              <v-chip outlined color="primary" class="ma-1">{{ tag }}</v-chip>
            </router-link>
          </v-row>
          <v-row>
            <v-btn
              color="primary"
              rounded
              elevation="8"
              :loading="loading"
              :dicabled="loading"
              class="text-capitalize mx-auto my-4"
              @click="getRecommendedTags()"
              >{{ moreRecommendationsText }}</v-btn
            >
          </v-row>
        </v-col>
      </v-row>
      <RecommendationsSheet @done="reload" />
      <ProductCards class="my-6" />
    </v-container>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import RecommendationsSheet from "@/components/layouts/RecommendationsSheet.vue";
import ProductCards from "@/components/home/ProductCards.vue";

export default {
  name: "Recommendations",
  components: { RecommendationsSheet, ProductCards },
  data() {
    return {
      tags: [],
      followingText: "Vitu Unavyo Fuatilia",
      recommendationsText: "Mapendekezo",
      relatedTags: [],
      loading: false,
      error: false,
      moreRecommendationsText: "Mapendekezo Zaidi",
    };
  },

  mounted() {
    this.getTags();
    this.getRecommendedTags();
  },
  methods: {
    reload() {
      this.getTags();
      this.getRecommendedTags();
    },
    getTags() {
      this.tags = JSON.parse(localStorage.getItem("tags"));
    },
    async getRecommendedTags() {
      if (this.tags.length == 0) return;
      this.loading = true;
      this.relatedTags = [];
      const db = firebase.firestore();
      const ref = db
        .collection("Products")
        .where("tags", "array-contains-any", this.getFilteredTags())
        .limit(10);
      const snapshot = await ref.get().catch((error) => {
        this.error = true;
        this.loading = false;
        console.log(error);
      });
      const docs = snapshot.docs;
      docs.forEach((doc) => {
        const productTags = doc.data().tags;
        productTags.forEach((tag) => {
          if (!this.tags.includes(tag) && !this.relatedTags.includes(tag))
            this.relatedTags.push(tag);
        });
      });
      this.relatedTags.sort(() => Math.random() - 0.5);
      this.loading = false;
    },
    getFilteredTags() {
      if (this.tags.length > 10) {
        let tagsToFilter = [];
        this.tags.forEach((tag) => {
          tagsToFilter.push(tag);
        });
        tagsToFilter.sort(() => Math.random() - 0.5);
        tagsToFilter.splice(10, tagsToFilter.length - 10);
        return tagsToFilter;
      } else return this.tags;
    },
  },
};
</script>
