<template>
  <v-app-bar
    app
    color="primary"
    dark
    elevate-on-scroll
    elevation="8"
    hide-on-scroll
  >
    <div class="d-flex align-center">
      <v-icon
        aria-label="Back Button"
        v-if="showBack"
        size="24px"
        class="mr-2"
        @click="back"
        >mdi-arrow-left-bold-circle</v-icon
      >
      <router-link v-if="!showBack" to="/">
        <v-img
          alt="MadukaYetu Logo"
          class="mr-2"
          contain
          :src="logo"
          transition="scale-transition"
          :width="$vuetify.breakpoint.mobile ? 32 : 45"
          color="#fffff0"
        />
      </router-link>

      <router-link to="/">
        <v-img
          alt="MadukaYetu Word Logo"
          class="mr-2"
          contain
          :src="logoWordmark"
          transition="slide-x-reverse-transition"
          color="#fffff0"
          height="20"
          :width="$vuetify.breakpoint.mobile ? 140 : 200"
        />
      </router-link>
    </div>

    <v-spacer></v-spacer>

    <div v-if="!$vuetify.breakpoint.mobile" class="mx-6">
      <router-link
        to="/"
        class="white--text text-decoration-none mx-2 font-weight-bold"
        >{{ homeText }}</router-link
      >
      <router-link
        to="/new"
        class="white--text text-decoration-none mx-2 font-weight-bold"
        >{{ newText }}</router-link
      >
      <router-link
        to="/recommendations"
        class="white--text text-decoration-none mx-2 font-weight-bold"
        >{{ recommendationsText }}</router-link
      >
      <router-link
        to="/location"
        class="white--text text-decoration-none mx-2 font-weight-bold"
        >{{ locationText }}</router-link
      >
    </div>
    <router-link to="/search" class="mx-2">
      <v-icon
        v-if="showSearch"
        transition="scale-transition"
        name="Search Button"
        aria-label="Search Button"
        >mdi-magnify</v-icon
      >
    </router-link>
    <LoginButton v-if="showLoginButton" />
  </v-app-bar>
</template>

<script>
import LoginButton from "@/components/layouts/LoginButton.vue";
export default {
  name: "Header",
  components: { LoginButton },
  data() {
    return {
      logo: require("@/assets/icons/logo.svg"),
      logoWordmark: require("@/assets/icons/logo_wordmark.svg"),
      showBack: false,
      showSearch: true,
      loginText: "Login",
      showLoginButton: true,
      recommendationsText: "Mapendekezo",
      locationText: "Mikoani",
      newText: "Bidhaa Mpya",
      homeText: "Home",
    };
  },
  methods: {
    back() {
      history.back();
    },
  },

  watch: {
    $route(to, from) {
      if (to.name === "Login") this.showLoginButton = false;
      else this.showLoginButton = true;
      if (from.name === "Home") this.showSearch = true;
      if (to.name === "Home") {
        this.showBack = false;
        this.showSearch = false;
      } else {
        this.showBack = true;
        this.showSearch = true;
      }
    },
  },
};
</script>

