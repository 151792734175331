<template>
  <div id="product-card">
    <v-card
      class="rounded-lg"
      elevation="12"
      color="white"
      style="overflow: hidden"
    >
      <router-link
        :to="{ path: 'product', query: { id: product.id } }"
        class="black--text text-decoration-none"
      >
        <v-img
          :src="product.data()['image_url']"
          alt="product-image"
          max-height="400"
          class="rounded-lg"
          transition="scale-transition"
        >
          <template v-slot:placeholder>
            <Placeholder />
          </template>
        </v-img>
      </router-link>
    </v-card>
    <h4 primary-title class="text-truncate mt-4">
      {{ product.data()["name"] }}
    </h4>
    <router-link
      :to="{ path: 'shop', query: { id: shop.id } }"
      class="black--text text-decoration-none"
    >
      <p class="body-2">{{ shop.name }}, {{ shop.region }}</p>
    </router-link>
  </div>
</template>

<script>
// import firebase from "firebase";
import firebase from "firebase/compat/app";
import Placeholder from "@/components/layouts/Placeholder.vue";
export default {
  name: "ProductCard",
  components: { Placeholder },
  props: { product: Object },
  data() {
    return {
      shop: {},
    };
  },
  methods: {
    getShop() {
      var db = firebase.firestore();
      var docRef = db.collection("Shops").doc(this.product.data().shop);
      docRef
        .get()
        .then((doc) => {
          this.shop = doc.data();
        })
        .catch((error) => console.log(error));
    },
    // goToProduct(product) {
    //   var productId = product.id;
    //   this.$router.push({ name: "Product", query: { id: productId } });
    //   window.scrollTo(0, 0);
    // },
    shortDescription(description) {
      if (description.length > 80) return description.substring(0, 80);
      else return description;
    },
    filteredTags(tags) {
      if (tags.length > 4) return tags.slice(0, 4);
      else return tags;
    },
  },
  mounted() {
    this.getShop();
  },
};
</script>

