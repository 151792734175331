<template>
  <div id="saved-section">
    <h3>{{ savedText }}</h3>

    <v-list color="white" v-for="(item, i) in items" :key="i">
      <v-list-item @click="handleSelectItem(item.key)">
        <v-list-item-avatar>
          <v-icon>{{ `mdi-${item.icon}` }}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-dialog
        v-model="productsDialog"
        scrollable
        max-width="500px"
        transition="dialog-transition"
        color="white"
      >
        <v-card>
          <v-card-title>
            {{ savedProductsText }}
          </v-card-title>
        </v-card>
      </v-dialog>
    </v-list>
  </div>
</template>

<script>
// import firebase from "firebase/compat/app";

export default {
  name: "SavedSection",
  data() {
    return {
      storesDialog: false,
      productsDialog: false,
      savedProducts: [],
      savedShops: [],
      savedText: "Maduka, Bidhaa na Vitambulisho",
      savedProductsText: "Maduka na Bidhaa Ulizohifadhi",

      items: [
        {
          title: "Maduka Niliyohifadhi",
          icon: "store",
          key: "saved-shops",
        },
        {
          title: "Bidhaa na Huduma Nilizohifadhi",
          icon: "shopping",
          key: "saved-products",
        },
        {
          title: "Vitambulisho Ninavyofuatilia",
          icon: "tag",
          key: "saved-tags",
        },
      ],
    };
  },

  methods: {
    handleSelectItem(key) {
      switch (key) {
        case "saved-shops":
          this.$router.push({ name: "SavedShops" });

          break;
        case "saved-products":
          this.$router.push({ name: "SavedProducts" });
          break;
        case "saved-tags":
          this.$router.push({ name: "Recommendations" });
          break;
      }
    },
    // async getSavedItems() {
    //   const db = firebase.firestore();
    //   const productsRef = db
    //     .collection("Users")
    //     .doc(user.id)
    //     .collection("SavedProducts");
    //   const shopsRef = db
    //     .collection("Users")
    //     .doc(user.id)
    //     .collection("ShopsProducts");
    // },
  },
};
</script>

