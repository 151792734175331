<template>
  <v-container>
    <SearchSection />
    <Shortcuts />
    <ProductCards />
    <Onboarding />
    <RecommendationsSheet />
  </v-container>
</template>

<script>
import SearchSection from "@/components/layouts/SearchSection.vue";
import ProductCards from "@/components/home/ProductCards.vue";
import Shortcuts from "@/components/home/Shortcuts.vue";
import Onboarding from "@/components/home/Onboarding.vue";
import RecommendationsSheet from "@/components/layouts/RecommendationsSheet.vue";

export default {
  name: "Home",
  components: {
    SearchSection,
    ProductCards,
    Shortcuts,
    Onboarding,
    RecommendationsSheet,
  },
  data() {
    return {
      tags: [],
    };
  },
  mounted() {
    this.getTags();
  },

  methods: {
    getTags() {
      if (localStorage.getItem("tags")) {
        try {
          this.tags = JSON.parse(localStorage.getItem("tags"));
        } catch (e) {
          localStorage.removeItem("tags");
        }
      }
    },
  },
};
</script>
