<template>
  <v-row justify="center" align="center" class="fill-height ma-6">
    <v-col :cols="$vuetify.breakpoint.mobile ? 8 : 4">
      <v-img
        :src="emptyView"
        :width="$vuetify.breakpoint.mobile ? '100vw' : '100%'"
        max-height="50vh"
        contain
        transition="scale-transition"
      ></v-img>
      <p class="d-flex-center text-center ma-auto py-4">{{ message }}</p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "EmptyView",
  computed: {
    emptyView() {
      const images = [
        require("@/assets/images/img_empty_01.svg"),
        require("@/assets/images/img_empty_02.svg"),
        require("@/assets/images/img_empty_03.svg"),
      ];
      images.sort(() => Math.random() - 0.5);
      return images[0];
    },
  },
  props: {
    message: String,
  },
};
</script>

