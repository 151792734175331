import Vue from 'vue'
import VueRouter from 'vue-router'
// import store from '../store/index.js'
import VueMeta from 'vue-meta';
import Home from '../views/Home.vue'
import Shop from '../views/Shop.vue'
import Product from '../views/Product.vue'
import Tag from '../views/Tag.vue'
import Search from '../views/Search.vue'
import NotFound from '../views/NotFound.vue'
import New from '../views/New.vue'
import Recommendations from '../views/Recommendations.vue'
import Location from '../views/Location.vue'
import Login from '../views/Login.vue'
import CreateShop from '../views/CreateShop.vue'
import CreateProduct from '../views/CreateProduct.vue'
import UserProfile from '../views/UserProfile.vue'
import SavedShops from '../views/SavedShops.vue'
import SavedProducts from '../views/SavedProducts.vue'




Vue.use(VueRouter)
Vue.use(VueMeta, {
    keyName: 'metaInfo',
    attribute: 'data-vue-meta',
    ssrAttribute: 'data-vue-meta-server-rendered',
    tagIDKeyName: 'vmid',
    refreshOnceOnNavigation: true
});



const routes = [{
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            title: 'Maduka Yetu - Biashara Yako Mtandaoni',
            metaTags: [{
                    name: 'description',
                    content: 'MadukaYetu inarahisisha upatikanaji wa Biashara Mtandaoni!'
                },
                {
                    property: 'og:description',
                    content: 'Weka Biashara Yako Mtandaoni'
                },
                {
                    property: 'og:url',
                    content: 'https://www.madukayetu.co.tz'
                }
            ]
        }
    },
    {
        path: '/shop/',
        name: 'Shop',
        component: Shop,
        meta: {
            title: 'MadukaYetu - Duka | Stores and Businesses',
            metaTags: [{
                    name: 'description',
                    content: 'Pata taarifa kuhusu maduka mbalimbali kwenye mtandao. Wasiliana moja kwa moja na wauzaji'
                },
                {
                    property: 'og:description',
                    content: 'Pata taarifa kuhusu maduka mbalimbali kwenye mtandao. Wasiliana moja kwa moja na wauzaji'
                },
            ]
        }
    },
    {
        path: '/product/',
        name: 'Product',
        component: Product,
        meta: {
            title: 'MadukaYetu - Bidhaa na Huduma | Products and Services',
            metaTags: [{
                    name: 'description',
                    content: 'Pata taarifa kuhusu maduka mbalimbali kwenye mtandao. Wasiliana moja kwa moja na wauzaji'
                },
                {
                    property: 'og:description',
                    content: 'Pata taarifa kuhusu bidhaa na huduma mbalimbali kwenye mtandao. Wasiliana moja kwa moja na wauzaji'
                },
            ]
        }
    },
    {
        path: '/tag/',
        name: 'Tag',
        component: Tag,
        meta: {
            title: 'MadukaYetu - Vitambulisho Vya Maduka, Bidhaa na Huduma | Tags for Businesses, Products and Services',
            metaTags: [{
                    name: 'description',
                    content: 'Tafuta bishara, bidhaa na huduma mbalimbali kupitia vitambulisho vyao'
                },
                {
                    property: 'og:description',
                    content: 'Tafuta bishara, bidhaa na huduma mbalimbali kupitia vitambulisho vyao'
                },
            ]
        }
    },
    {
        path: '/search/',
        name: 'Search',
        component: Search,
        meta: {
            title: 'MadukaYetu - Tafuta Maduka, Bidhaa na Huduma | Search for Businesses, Products and Services',
            metaTags: [{
                    name: 'description',
                    content: 'Tafuta bishara, bidhaa na huduma mbalimbali kupitia vitambulisho vyao'
                },
                {
                    property: 'og:description',
                    content: 'Tafuta bishara, bidhaa na huduma mbalimbali kupitia vitambulisho vyao'
                },
            ]
        }
    },
    {
        path: '/new/',
        name: 'New',
        component: New,
        meta: {
            title: 'MadukaYetu - Bidhaa Huduma na Maduka Mapya | New Shops, Products and Services',
            metaTags: [{
                    name: 'description',
                    content: 'Pata huduma na bidhaa mpya kutoka kwenye maduka mapya kwenye Madukayetu'
                },
                {
                    property: 'og:description',
                    content: 'Pata huduma na bidhaa mpya kutoka kwenye maduka mapya kwenye Madukayetu'
                },
            ]
        }
    },
    {
        path: '/recommendations/',
        name: 'Recommendations',
        component: Recommendations,
        meta: {
            title: 'MadukaYetu - Mapendekezo | Recommendations',
            metaTags: [{
                    name: 'description',
                    content: 'Mapendekezo ya Maduka, Huduma na Bidhaa'
                },
                {
                    property: 'og:description',
                    content: 'Mapendekezo ya Maduka, Huduma na Bidhaa'
                },
            ]
        }
    },
    {
        path: '/location/',
        name: 'Location',
        component: Location,

    },
    {
        path: '/login/',
        name: 'Login',
        component: Login,

    },
    {
        path: '/createshop/',
        name: 'CreateShop',
        component: CreateShop,
        meta: {
            requiresAuth: true
        }

    },
    {
        path: '/createproduct/',
        name: 'CreateProduct',
        component: CreateProduct,
        meta: {
            requiresAuth: true
        }

    },
    {
        path: '/userprofile/',
        name: 'UserProfile',
        component: UserProfile,
        meta: {
            requiresAuth: true
        }

    },
    {
        path: '/savedshops/',
        name: 'SavedShops',
        component: SavedShops,
        meta: {
            requiresAuth: true
        }

    },
    {
        path: '/savedproducts/',
        name: 'SavedProducts',
        component: SavedProducts,
        meta: {
            requiresAuth: true
        }

    },
    {
        // will match everything
        path: '*',
        component: NotFound,
    }
]



const router = new VueRouter({
    base: process.env.BASE_URL,
    routes,
    root: '/',
    mode: 'history'
})

router.beforeEach((to, from, next) => {


    // const isAuthenticated = store.state.loggedIn //localStorage.loggedIn === 'true'
    const isAuthenticated = localStorage.loggedIn === 'true'

    if (to.meta.requiresAuth && !isAuthenticated) next({ name: 'Login' })
    else next()
})


export default router