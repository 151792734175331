<template>
  <div id="onboarding">
    <v-dialog
      color="primary"
      :value="firstOpen"
      :overlay="false"
      persistent
      :max-width="$vuetify.breakpoint.mobile ? '100%' : '50vw'"
    >
      <v-card color="primary" dark class="mx-auto py-4">
        <v-img
          :src="onboardImage"
          contain
          :max-width="$vuetify.breakpoint.mobile ? '100%' : '20vw'"
          max-height="30vh"
          class="mx-auto my-4"
        ></v-img>

        <v-card-title class="text-center" style="justify-content: center">
          <h2>
            {{ welcomeText }}
          </h2>
        </v-card-title>
        <v-card-text class="text-center col-lg-8 mx-auto">
          <p class="white--text">
            {{ introText }}
          </p>
        </v-card-text>
        <v-card-actions style="justify-content: center">
          <v-btn
            rounded
            light
            large
            color="white"
            elevation="8"
            @click="updateFirstOpen"
            class="text-capitalize"
            >{{ enterText }}</v-btn
          >
        </v-card-actions>
        <v-card-actions
          @click="goToTerms"
          class="
            text-center text-caption
            font-weight-light
            col-lg-6
            mx-auto
            text-decoration-underline
          "
        >
          <v-row justify="center" class="text-center my-4">
            {{ termsText }}
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import LogoSection from "@/components/layouts/LogoSection.vue";
export default {
  name: "Onboarding",
  // components: { LogoSection },
  data() {
    return {
      firstOpen: false,
      onboardImage: require("@/assets/images/img_online_shopping.svg"),
      logo: require("@/assets/icons/logo.svg"),
      logoWordmark: require("@/assets/icons/logo_wordmark.svg"),
      welcomeText: "Karibu MadukaYetu",
      enterText: "Ingia",
      introText:
        "MadukaYetu ni sehemu ya kuona Biashara, Huduma na Bidhaa zinazopatikana kwenye jamii inayo tuzunguka.",
      termsUrl: "https://about.madukayetu.co.tz/#/terms",
      termsText:
        "Kwa kutumia MadukaYetu, unakubali kuzingatia vigezo na masharti ya matumizi ya MadukaYetu",
    };
  },
  methods: {
    isFirstOpen() {
      if (localStorage.getItem("first-open")) {
        try {
          this.firstOpen = JSON.parse(localStorage.getItem("first-open"));
        } catch (e) {
          localStorage.removeItem("firstOpen");
          this.firstOpen = true;
        }
      } else this.firstOpen = true;
    },
    updateFirstOpen() {
      this.firstOpen = false;
      const parsedFirstOpen = JSON.stringify(this.firstOpen);
      localStorage.setItem("first-open", parsedFirstOpen);
      this.isFirstOpen();
    },
    goToTerms() {
      window.open(this.termsUrl);
    },
  },
  mounted() {
    this.isFirstOpen();
  },
};
</script>

