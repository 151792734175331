<template>
  <v-container class="primary fill-height" grid-list-xs fluid>
    <v-row justify="center" align="center" class="primary">
      <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 8">
        <v-row justify="center" class="pa-4 ma-4">
          <v-img
            :src="loginImage"
            :alt="imageDescription"
            contain
            :max-width="$vuetify.breakpoint.mobile ? '80%' : '30%'"
          ></v-img>
        </v-row>
        <h2 primary-title class="white--text text-center">
          {{ loginText }}
        </h2>
        <p class="text-center text-body-1 col-lg-8 mx-auto white--text">
          {{ introText }}
        </p>
        <v-row>
          <v-btn
            rounded
            light
            large
            :loading="loading"
            :disabled="loading"
            color="white"
            elevation="8"
            @click="login"
            class="text-capitalize mx-auto"
            >{{ loginText }}</v-btn
          >
        </v-row>
        <v-snackbar
          :value="error"
          rounded="pill"
          timeout="2000"
          elevation="8"
          class="text-center white--text mx-auto mt-4"
          >{{ errorMessage }}</v-snackbar
        >

        <a
          @click="goToTerms"
          class="
            d-flex
            white--text
            text-center text-caption
            font-weight-light
            col-lg-6
            mx-auto
            mt-3
            text-decoration-underline
          "
        >
          {{ termsText }}
        </a>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from "firebase/compat/app";
export default {
  name: "Login",
  props: {
    showLogin: Boolean,
  },

  data() {
    return {
      loginText: "Login",
      introText:
        "Karibu kulogin, kuingia MadukaYetu. Uki-login utaweza kutengeneza Duka lako, ku-like na kuhifadhi maduka na bidhaa. Karibu.",
      termsUrl: "https://about.madukayetu.co.tz/terms",
      termsText:
        "Kwa kutumia MadukaYetu, unakubali kuzingatia vigezo na masharti ya matumizi ya MadukaYetu",
      error: false,
      loading: false,
      errorMessage: "Kuna hitilafu imetokea, tafadhali jaribu tena.",
      imageDescription: "Login Image",
      previousPage: "",
    };
  },

  computed: {
    loginImage() {
      const images = [
        require("@/assets/images/img_login_01.svg"),
        require("@/assets/images/img_login_02.svg"),
        require("@/assets/images/img_login_03.svg"),
        require("@/assets/images/img_login_04.svg"),
        require("@/assets/images/img_login_05.svg"),
      ];
      images.sort(() => Math.random() - 0.5);
      return images[0];
    },
  },
  methods: {
    async login() {
      this.loading = true;
      this.error = false;
      const provider = new firebase.auth.GoogleAuthProvider();
      const auth = firebase.auth();
      const result = await auth.signInWithPopup(provider).catch((error) => {
        this.error = true;
        this.loading = false;
        console.log("error in login in: " + error.message);
      });

      this.loading = false;
      if (!this.error && result.user) this.goBack();
    },

    goBack() {
      this.$router.go(-1);
    },
    goToTerms() {
      window.open(this.termsUrl);
    },
  },
};
</script>
