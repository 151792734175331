<template>
  <v-container grid-list-xs>
    <LoadingView v-if="loading" />
    <ErrorView v-if="error" />
    <NotFound v-if="badUrl" />
    <v-row v-if="!badUrl && !error && !loading" justify="center" align="center">
      <v-col lg="8">
        <v-img
          :src="product.image_url"
          class="rounded-lg"
          max-height="80vh"
          transition="scale-transition"
        >
          <template v-slot:placeholder>
            <Placeholder />
          </template>

          <LikeButton />
        </v-img>

        <v-card flat color="white">
          <v-card-title>
            <h1 style="word-break: normal; line-height: 1em">
              {{ product.name }}
            </h1>
          </v-card-title>

          <v-card-text  >
            <p>

            {{ product.description }}
            </p>
          </v-card-text>

          <v-card-title v-if="product.price"
            ><h3>
              {{ product.price }}
            </h3>
          </v-card-title>
          <ContactButton :shop="shop" />
          <v-card-actions>
            <v-row justify="center" align="center" class="my-8">
              <ShareButton />
              <CommentButton />
              <SaveProductButton />
            </v-row>
          </v-card-actions>
          <Comments />
          <v-list
            v-if="!loading"
            subheader
            class="white my-4 rounded-lg"
            elevation="8"
          >
            <v-subheader class="text-h6 black--text">{{
              shopText
            }}</v-subheader>

            <router-link
              :to="{ name: 'Shop', query: { id: shopId } }"
              class="black--text text-decoration-none"
            >
              <v-list-item>
                <v-list-item-avatar>
                  <v-img
                    :src="shop.image_url"
                    alt="Shop Image"
                    max-height="200"
                    max-width="200"
                  ></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    >{{ shop.name }}, {{ shop.region }}</v-list-item-title
                  >
                  <v-list-item-subtitle
                    v-text="shop.description"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </router-link>
          </v-list>

          <v-card-text>
            <v-row>
              <v-chip
                v-for="(tag, i) in productTags"
                :key="i"
                :to="{ name: 'Tag', query: { tag: tag } }"
                small
                color="primary"
                class="ma-1"
                >{{ tag }}</v-chip
              >
            </v-row>
          </v-card-text>
          <RelatedProducts :product="product" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from "firebase/compat/app";
import LoadingView from "@/components/layouts/LoadingView";
import ErrorView from "@/components/layouts/ErrorView";
import Placeholder from "@/components/layouts/Placeholder";
import ShareButton from "@/components/layouts/ShareButton";
import NotFound from "@/views/NotFound";
import RelatedProducts from "@/components/product/RelatedProducts";
import ContactButton from "@/components/layouts/ContactButton";
import SaveProductButton from "@/components/layouts/SaveProductButton";
import Comments from "@/components/layouts/Comments";
import CommentButton from "@/components/layouts/CommentButton";
import LikeButton from "@/components/layouts/LikeButton";
export default {
  name: "Product",
  components: {
    LoadingView,
    ErrorView,
    Placeholder,
    ShareButton,
    NotFound,
    RelatedProducts,
    ContactButton,
    SaveProductButton,
    Comments,
    CommentButton,
    LikeButton,
  },
  watch: {
    $route(to, from) {
      const currentProductId = from.query.id;
      const toProductId = to.query.id;
      if (currentProductId !== toProductId) this.getProduct();
    },
  },
  data() {
    return {
      productId: "",
      product: "",
      shop: {},
      shopId: "",
      contacts: [],
      sheet: false,
      contact: "Mawasiliano",
      shopText: "Duka",
      loading: true,
      error: false,
      url: "",
      badUrl: false,
    };
  },
  metaInfo() {
    if (this.loading) return this.defaultMeta();
    else return this.getMeta();
  },

  methods: {
    async getProduct() {
      this.loading = true;
      this.productId = this.$router.currentRoute.query.id;
      if (!this.productId) return;
      const db = firebase.firestore();
      const docRef = db.collection("Products").doc(this.productId);
      const doc = await docRef.get().catch((error) => {
        console.log(error);
        this.loading = false;
        this.error = true;
      });
      if (this.error) return;
      if (!doc.exists) {
        this.loading = false;
        this.badUrl = true;
        return;
      }

      this.product = doc.data();

      await this.getShop();
      this.loading = false;
    },
    async getShop() {
      const db = firebase.firestore();
      const docRef = db.collection("Shops").doc(this.product.shop);
      const doc = await docRef.get().catch((error) => {
        console.log(error);
        this.error = true;
      });
      this.shop = doc.data();
      this.shopId = doc.id;
      this.loading = false;
    },

    getMeta() {
      return {
        title: this.product.name,
        titleTemplate: "%s - MadukaYetu",
        htmlAttrs: {
          lang: "en-US",
        },
        meta: [
          { charset: "utf-8" },

          {
            name: "description",
            vmid: "description",
            content: this.product.description,
          },
          {
            name: "og:description",
            content: this.product.description,
          },
          {
            name: "og:url",
            content:
              window.location.origin + this.$router.currentRoute.fullPath,
          },
          {
            name: "og:image",
            content: this.product["image_url"],
          },
          { name: "viewport", content: "width=device-width, initial-scale=1" },
        ],
      };
    },
    defaultMeta() {
      return {
        title: "Huduma na Bidhaa",
        titleTemplate: "%s - MadukaYetu",
        htmlAttrs: {
          lang: "en-US",
        },
        meta: [
          { charset: "utf-8" },

          {
            name: "description",
            vmid: "description",
            content: "Pata Bidhaa na Huduma mbalimbali kwenye jamii yetu",
          },
          {
            name: "og:description",
            content: "Pata Bidhaa na Huduma mbalimbali kwenye jamii yetu",
          },
          {
            name: "og:url",
            content:
              window.location.origin + this.$router.currentRoute.fullPath,
          },
          {
            name: "og:image",
            content: "https://madukayetu.co.tz/app/icons/Icon-192.png",
          },
        ],
      };
    },
  },

  mounted() {
    this.productId = this.$router.currentRoute.query.id;
    this.getProduct();
  },
  computed: {
    productTags() {
      let tags = [];
      this.product.tags.forEach((tag) => {
        if (tag) tags.push(tag);
      });
      return tags;
    },
  },
};
</script>

