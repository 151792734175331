<template>
  <div>
    <div v-if="tab === 'products'" class="white pb-6">
      <v-container grid-list-xs v-if="!loading">
        <v-row justify="center" align="center">
          <v-col
            v-for="product in products"
            :key="product.id"
            :cols="$vuetify.breakpoint.mobile ? 6 : 3"
          >
            <ProductCard :product="product" />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div v-if="tab === 'shops'" class="white pb-6">
      <v-container grid-list-xs v-if="!loading">
        <v-row justify="center" align="center">
          <v-col
            v-for="shop in shops"
            :key="shop.id"
            :cols="$vuetify.breakpoint.mobile ? 6 : 3"
          >
            <ShopCard :shop="shop" />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <LoadingView v-if="loading" />
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import ShopCard from "@/components/layouts/ShopCard.vue";
import ProductCard from "@/components/layouts/ProductCard.vue";
import LoadingView from "@/components/layouts/LoadingView.vue";

export default {
  name: "NewArrivalsItems",
  props: { tab: String },
  components: {
    ShopCard,
    // SmallShopCard,
    ProductCard,
    // SmallProductCard,
    LoadingView,
  },
  mounted() {
    this.getItems();
  },
  data() {
    return {
      shops: [],
      products: [],
      loading: true,
    };
  },
  methods: {
    async getItems() {
      this.loading = true;
      const db = firebase.firestore();
      const shopsRef = db
        .collection("Shops")
        .orderBy("created_at", "desc")
        .limit(24);
      const productsRef = db
        .collection("Products")
        .orderBy("created_at", "desc")
        .limit(24);

      switch (this.tab) {
        case "shops":
          this.getShops(shopsRef);
          break;
        case "products":
          this.getProducts(productsRef);
          break;
      }
    },
    async getProducts(ref) {
      const snapshot = await ref.get().catch((error) => console.log(error));
      this.products = snapshot.docs;
      this.products.sort(() => Math.random() - 0.5);
      this.loading = false;
    },
    async getShops(ref) {
      const snapshot = await ref.get().catch((error) => console.log(error));
      this.shops = snapshot.docs;
      this.shops.sort(() => Math.random() - 0.5);
      this.loading = false;
    },
  },
};
</script>

