<template>
  <div id="user-profile">
    <v-container>
      <v-row justify="center">
        <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 8">
          <v-row justify="center" class="my-2">
            <v-avatar size="80">
              <v-img :src="user.image_url" :alt="userProfilePhotoText"></v-img>
            </v-avatar>
          </v-row>
          <h2 class="text-center">{{ user.name }}</h2>
          <p class="text-center mx-auto">{{ user.email }}</p>
          <v-divider></v-divider>
          <SavedSection />
          <v-divider></v-divider>

          <h3>{{ myShopsText }}</h3>
          <v-list color="white">
            <v-list-item @click="goTo('CreateShop')">
              <v-list-item-avatar color="primary">
                <v-icon color="white">mdi-store</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ createShopText }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <MyShopsList />
          <v-divider></v-divider>
          <v-list color="white">
            <v-list-item @click="logout">
              <v-list-item-avatar>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ logoutText }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import { mapGetters } from "vuex";
import MyShopsList from "@/components/layouts/MyShopsList.vue";
import SavedSection from "@/components/userprofile/SavedSection.vue";
export default {
  name: "UserProfile",
  components: { MyShopsList, SavedSection },
  data() {
    return {
      userProfilePhotoText: "User Profile Photo",
      myShopsText: "Maduka Yangu",
      createShopText: "Tengeneza Duka",
      logoutText: "Logout",
      savedText: "Maduka na Bidhaa Niliyohifadhi",
    };
  },
  computed: {
    ...mapGetters({
      user: "currentUser",
    }),
  },
  methods: {
    goTo(page) {
      this.$router.push({ name: page });
    },

    async logout() {
      const auth = firebase.auth();
      await auth.signOut();
      this.$router.push({ name: "Home" });
      //   await this.checkUser();
    },
  },
};
</script>

