<template>
  <router-link
    :to="{ path: '/shop', query: { id: shop.id } }"
    class="text-decoration-none black--text"
  >
    <v-card
      class="rounded-lg"
      elevation="12"
      color="white"
      style="overflow: hidden"
    >
      <v-img
        :src="shop.data()['image_url']"
        alt="shop-image"
        max-height="400"
        class="rounded-lg"
        transition="scale-transition"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <Placeholder />
          </v-row>
        </template>
      </v-img>
    </v-card>
    <h4 primary-title class="text-truncate mt-4">
      {{ shop.data()["name"] }}
    </h4>
    <p class="body-2">
      {{ shop.data()["region"] }}
    </p>
  </router-link>
</template>

<script>
import Placeholder from "@/components/layouts/Placeholder.vue";
export default {
  name: "ShopCard",
  components: { Placeholder },
  props: { shop: Object },
  methods: {
    shortDescription(description) {
      if (description.length > 80) return description.substring(0, 80);
      else return description;
    },
    filteredTags(tags) {
      if (tags.length > 4) return tags.slice(0, 4);
      else return tags;
    },
  },
};
</script>

