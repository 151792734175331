<template>
  <div id="like-button">
    <v-btn
      fab
      bottom
      right
      absolute
      color="white"
      aria-label="Like Button"
      class="mb-12"
      transition="scale-transition"
      :loading="loading"
      @click="like"
    >
      <v-icon color="primary">{{ icon }}</v-icon>
    </v-btn>

    <v-dialog v-model="dialog" max-width="500px" transition="dialog-transition">
      <v-card color="white">
        <v-card-title primary-title>
          {{ loginText }}
        </v-card-title>

        <v-card-actions>
          <v-row justify="center" class="py-2 my-4">
            <v-btn
              rounded
              elevation="8"
              color="primary"
              class="text-capitalize"
              to="/login"
              >{{ loginMessage }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
export default {
  data() {
    return {
      loading: false,
      hasLiked: false,
      dialog: false,
      loginText: "Login",
      loginMessage: "Login ku-like",
    };
  },
  mounted() {
    this.getStatus();
  },
  methods: {
    async getStatus() {
      if (localStorage.loggedIn !== "true") return;
      this.loading = true;
      const db = firebase.firestore();
      const collectionName = this.getCollection();
      const id = this.$router.currentRoute.query.id;
      const currnetUserId = this.$store.getters.currentUser.id;
      const ref = db.doc(`${collectionName}/${id}/Likes/${currnetUserId}`);
      const doc = await ref.get();
      this.hasLiked = doc.exists;
      this.loading = false;
    },
    getCollection() {
      const pageName = this.$router.currentRoute.name;
      switch (pageName) {
        case "Product":
          return "Products";
        case "Shop":
          return "Shops";
        default:
          return "";
      }
    },
    async like() {
      if (localStorage.loggedIn !== "true") {
        this.dialog = true;
        return;
      }
      this.loading = true;
      try {
        const db = firebase.firestore();
        const collectionName = this.getCollection();
        const id = this.$router.currentRoute.query.id;
        const currnetUserId = this.$store.getters.currentUser.id;
        const ref = db.doc(`${collectionName}/${id}/Likes/${currnetUserId}`);
        const data = {
          created_by: this.$store.state.currentUser.id,
          created_at: Date.now(),
        };
        if (this.hasLiked) await ref.delete();
        else await ref.set(data);
        this.loading = false;
        this.hasLiked = !this.hasLiked;
      } catch (error) {
        console.log("error on like:", error);
        this.loading = false;
      }
    },
  },
  computed: {
    icon() {
      if (this.hasLiked) return "mdi-heart";
      else return "mdi-heart-outline";
    },
  },
};
</script>

