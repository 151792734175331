<template>
  <v-card-actions class="mx-auto">
    <v-btn
      color="primary"
      large
      block
      rounded
      elevation="8"
      class="text-capitalize"
      @click="showSheet"
      >{{ contact }}</v-btn
    >

    <v-bottom-sheet v-model="sheet" inset>
      <v-list class="white rounded-lg">
        <v-list-item
          v-for="contact in contacts"
          :key="contact.phone"
          two-line
          @click="open(contact.url)"
        >
          <v-avatar size="32px" tile>
            <v-icon color="primary">{{ `mdi-${contact.icon}` }}</v-icon>
          </v-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ contact.detail }}</v-list-item-title>
            <v-list-item-subtitle>{{ contact.title }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-bottom-sheet>
  </v-card-actions>
</template>

<script>
export default {
  name: "ContactButton",
  props: {
    shop: Object,
  },
  data() {
    return {
      contact: "Mawasiliano",
      contacts: [],
      sheet: false,
    };
  },
  mounted() {
    this.setupContactDetails();
  },

  methods: {
    showSheet() {
      this.setupContactDetails();
      this.sheet = true;
    },
    setupContactDetails() {
      if (!this.shop) return;
      this.contacts = [];
      var phone = this.shop["phone"];
      var whatsapp = this.shop["whatsapp"];
      var email = this.shop["email"];
      var facebook = this.shop["facebook"];
      var twitter = this.shop["twitter"];
      var instagram = this.shop["instagram"];
      var website = this.shop["website"];

      if (phone) {
        this.contacts.push({
          title: "phone",
          detail: phone,
          icon: "phone",
          url: "tel:" + phone,
        });
      }
      if (phone) {
        this.contacts.push({
          title: "SMS",
          detail: phone,
          icon: "message",
          url: "sms:" + phone,
        });
      }
      if (whatsapp) {
        this.contacts.push({
          title: "whatsapp",
          detail: whatsapp,
          icon: "whatsapp",
          url: "https://wa.me/" + this.parseNumber(whatsapp),
        });
      }
      if (instagram) {
        this.contacts.push({
          title: "instagram",
          detail: instagram,
          icon: "instagram",
          url: "https://www.instagram.com/" + instagram,
        });
      }
      if (facebook) {
        this.contacts.push({
          title: "facebook",
          detail: facebook,
          icon: "facebook",
          url: "https://www.facebook.com/" + facebook,
        });
      }
      if (twitter) {
        this.contacts.push({
          title: "twitter",
          detail: twitter,
          icon: "twitter",
          url: "https://www.twitter.com/" + twitter,
        });
      }
      if (website) {
        this.contacts.push({
          title: "website",
          detail: website,
          icon: "web",
          url: website,
        });
      }
      if (email) {
        this.contacts.push({
          title: "email",
          detail: email,
          icon: "email",
          url: "mailto:" + email,
        });
      }
    },
    open(url) {
      this.sheet = false;
      window.open(url);
      this.$gtag.event("Contact Button", { method: url });
    },
    parseNumber(number) {
      if (number[0] === "0") return `+255${number}`;
      else return number;
    },
  },
};
</script>

