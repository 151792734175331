<template>
  <v-btn
    v-if="show"
    rounded
    outlined
    block
    color="primary"
    class="text-capitalize mt-6"
    @click="goToCreateProduct"
    >{{ createProductText }}</v-btn
  >
</template>

<script>
import firebase from "firebase/compat/app";
export default {
  name: "CreateProductButton",

  data() {
    return {
      shopId: this.$router.currentRoute.query.id,
      user: this.$store.state.currentUser,
      createProductText: "Tengeneza Bidhaa",
      show: false,
    };
  },
  mounted() {
    this.showCreateButton();
  },

  methods: {
    async showCreateButton() {
      const loggedIn = this.$store.state.loggedIn;
      if (
        loggedIn &&
        ((await this.admin()) || (await this.owner()) || (await this.creator()))
      )
        this.show = true;
    },
    async getShop() {
      const db = firebase.firestore();
      if (!this.shopId) return;
      const ref = db.collection("Shops").doc(this.shopId);
      const doc = await ref.get().catch((error) => console.log(error));
      return doc.data();
    },
    admin() {
      return this.user["is_admin"];
    },
    async owner() {
      const shop = await this.getShop();
      if (shop) return shop.owner === this.user.id;
    },
    async creator() {
      const shop = await this.getShop();
      if (shop) return shop["created_by"] === this.user.id;
    },
    goToCreateProduct() {
      this.$router.push({
        name: "CreateProduct",
        query: { shop: this.shopId },
      });
    },
  },
};
</script>