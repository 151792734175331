<template>
  <div id="saved-products">
    <v-container>
      <h1 class="text-center">{{ savedProductsText }}</h1>
      <v-container grid-list-xs v-if="!loading && !error">
        <EmptyView v-if="!loading && noProducts" :message="emptyProductsText" />
        <v-row v-else justify-lg="center">
          <v-col v-for="product in products" :key="product.id" cols="6" lg="3">
            <ProductCard :product="product" />
          </v-col>
          <LoadMoreButton
            v-show="hasMore"
            :loading="loadingMore"
            @loadmore="loadMore"
          />
        </v-row>
      </v-container>
      <LoadingView v-if="loading" />
      <ErrorView v-if="error" />
    </v-container>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import ProductCard from "@/components/layouts/ProductCard.vue";
import LoadingView from "@/components/layouts/LoadingView.vue";
import EmptyView from "@/components/layouts/EmptyView.vue";
import ErrorView from "@/components/layouts/ErrorView.vue";
import LoadMoreButton from "@/components/layouts/LoadMoreButton.vue";

export default {
  name: "SavedProducts",
  components: {
    ProductCard,
    LoadingView,
    EmptyView,
    ErrorView,
    LoadMoreButton,
  },
  data() {
    return {
      loading: false,
      error: false,
      products: [],
      loadingMore: false,
      loadLimit: 12,
      hasMore: false,
      savedProductsText: "Bidhaa Nilizohifadhi",
      emptyProductsText: "Hakuna bidhaa ulizohifadhi",
      lastDoc: null,
    };
  },
  mounted() {
    this.getSavedProducts();
  },
  computed: {
    noProducts() {
      return this.products.length === 0;
    },
  },
  methods: {
    async getSavedProducts() {
      try {
        this.loading = true;
        const db = firebase.firestore();
        const userId = this.$store.state.currentUser.id;
        const ref = db
          .collection("Users")
          .doc(userId)
          .collection("SavedProducts")
          .limit(this.loadLimit);
        const snapshot = await ref.get();
        const docs = snapshot.docs;
        const hasProducts = docs.length > 0;
        this.hasMore = docs.length === this.loadLimit;
        if (!hasProducts) {
          this.loading = false;
          return;
        }
        this.lastDoc = docs[docs.length - 1];
        await this.getProducts(db, docs);
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.error = true;
        this.loading = false;
      }
    },
    async getProducts(db, docs) {
      docs.forEach(async (savedDoc) => {
        const ref = db.collection("Products").doc(savedDoc.id);
        const doc = await ref.get().catch((error) => console.log(error));
        this.products.push(doc);
      });
    },
    async loadMore() {
      this.loadingMore = true;
      try {
        const db = firebase.firestore();
        const userId = this.$store.state.currentUser.id;
        const ref = db
          .collection("Users")
          .doc(userId)
          .collection("SavedProducts")
          .limit(this.loadLimit);
        const snapshot = await ref.startAfter(this.lastDoc).get();
        const docs = snapshot.docs;
        this.hasMore = docs.length === this.loadLimit;
        this.lastDoc = docs[docs.length - 1];
        await this.getProducts(db, docs);
      } catch (error) {
        console.log(error);
        this.error = true;
        this.loadingMore = false;
      }
      this.loadingMore = false;
    },
  },
};
</script>



